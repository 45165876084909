export const UpdateAuthenticatedUser = (user: { accessToken: string, authenticated: boolean }) => {
    return {
        type: 'USER_AUTHENTICATED',
        user: user,
    }
}

export const UserSignOut = () => {
    return {
        type: 'USER_LOGGED_OUT',
    }
}
