import { useSelector } from "react-redux";

interface StoreState { user: { accessToken: string, authenticated: boolean } }

export const useRootStoreSelector = <TSelected = unknown>(
  selector: (state: StoreState) => TSelected,
  equalityFn?: (left: TSelected, right: TSelected) => boolean
): TSelected => {
  const result = useSelector<StoreState, TSelected>(selector, equalityFn);

  return result;
};
