import React, { ReactNode } from 'react';
import { cssTransition, toast, ToastContentProps } from 'react-toastify';
import i18n from '../../translations/i18n'

const offerionToast = (node: (props: ToastContentProps) => React.ReactNode, options?: { autoClose?: number }) => {
    toast(node,
        {
            bodyClassName: "notification-body",
            className: "notification-container",
            hideProgressBar: true,
            autoClose: options && options.autoClose ? options.autoClose : undefined,
            draggable: false,
            closeButton: false,
            transition: cssTransition({
                enter: 'zoomIn',
                exit: 'zoomOut',
                duration: [0, 500]
            })
        });
}

const Header = ({ type }: { type: string }) => {
    return <h3 className={`notification__title notification__title--${type.toLocaleLowerCase()}`}>{type}</h3>
}

export const toastSuccess = (messageKey: string, options?: {}) => {
    offerionToast(props => <div className="notification">
        <a className="notification__close icono-cross pointer" onClick={props.closeToast}></a>
        <Header type="Success" />
        <p className="notification__message">{i18n.t(messageKey, options)}</p>
    </div>)
}

export const toastInfo = (messageKey: string) => {
    offerionToast(props => <div className="notification">
        <a className="notification__close icono-cross pointer" onClick={props.closeToast}></a>
        <Header type="Info" />
        <p className="notification__message">{i18n.t(messageKey)}</p>
    </div>)
}

export const toastWarning = (messageKey: string, skipTranslate?: boolean, autoClose?: number) => {
    offerionToast(props => <div className="notification">
        <a className="notification__close icono-cross pointer" onClick={props.closeToast}></a>
        <Header type="Warning" />
        <p className="notification__message">{skipTranslate ? messageKey : i18n.t(messageKey)}</p>
    </div>, {
        autoClose
    })
}

export const toastError = (messageKey: string) => {
    offerionToast(props => <div className="notification">
        <a className="notification__close icono-cross pointer" onClick={props.closeToast}></a>
        <Header type="Error" />
        <p className="notification__message">{i18n.t(messageKey)}</p>
    </div>)
}