import React, { ReactNode, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Formik, Form, Field, FieldAttributes, FieldArray } from "formik";
import { useHistory } from 'react-router-dom';
import { ApiClient } from '../../shared/api/api-client';
import { toastSuccess } from '../../shared/toastr';

enum VisibleModalType {
    None,
    Header,
    Signature,
    Footer
}

const CheckboxRow = ({ name, labelKey }: { name: string, labelKey: string }) => {

    const { t } = useTranslation();

    return <div className="info__row">
        <Field id={name} type="checkbox" name={name} />
        <label htmlFor={name} className="button button--gray checkbox-button">
            <span className="checkbox-button__icon"></span>
        </label>
        <label htmlFor={name}>{t(labelKey)}</label>
    </div>
}

const TextRow = ({ labelKey, placeholderKey, ...rest }: { labelKey: string, placeholderKey: string } & FieldAttributes<any>) => {

    const { t } = useTranslation();

    return <div className="info__row">
        <label className="info__row-name">{t(labelKey)}</label>
        <div className="info__row-content info__row-content--with-button">
            <div className="">
                <Field type="text"
                    placeholder={t(placeholderKey)}
                    {...rest} />
            </div>
        </div>
    </div>
}


const Section = ({ sectionTitleKey, children, collapsed }: { sectionTitleKey: string, children: ReactNode, collapsed?: boolean }) => {

    const { t } = useTranslation();

    return <>
        <div className="invoices__title invoices__title--short  invoices__title--with-button">
            <h1 style={{ display: "inline", cursor: "pointer" }}>{t(sectionTitleKey)}</h1>
        </div>

        <div className="table__outer info__additional info__additional--hide" style={{ height: "auto" }}>
            <div className="info__container">
                {children}
            </div>
        </div>
    </>
}

interface DocumentTranslation {
    cultureName: string;
}

export const DocumentTranslationsContainer = () => {

    const { t } = useTranslation();
    const history = useHistory();
    const [selectedLanguage, setSelectedLanguage] = useState('hr-hr');
    const [languages, setSettings] = useState<DocumentTranslation[] | null>(null)

    useEffect(() => {
        ApiClient.get<DocumentTranslation[]>(`/api/DocumentTranslations/GetDocumentTranslations`).then(setSettings)
    }, [])



    async function onSubmit(documentTranslation: DocumentTranslation) {

        if (!languages)
            return;

        const indexToEdit = languages?.findIndex(x => x.cultureName == documentTranslation.cultureName);

        languages[indexToEdit] = documentTranslation;

        await ApiClient.post('api/DocumentTranslations/UpdateDocumentTranslations', languages);

        toastSuccess('Document translations updated');
    }

    if (!languages)
        return null;

    const languageToEdit = languages.find(x => x.cultureName == selectedLanguage);

    if (!languageToEdit)
        return null;

    return <>

        <BreadcrumbsItem to="/document-translations">
            Translations
        </BreadcrumbsItem>

        <main className="main">
            <Formik initialValues={languageToEdit} enableReinitialize={true} onSubmit={languages => onSubmit(languages)}>
                {
                    formikProps => <Form noValidate={true}>
                        <div className="main__header">
                            <div className="main-title">{t('Translations')}</div>

                            <div className="main__header-buttons main__header-buttons--show-all">
                                <div className="button-outer">
                                    <button className="button button--white button--padded" type="submit">
                                        <span className="icon icon__save"></span>
                                        <span className="button-text">{t('Allaround.Button.save')}</span>
                                    </button>
                                </div>
                                <div className="button-outer">
                                    <button className="button button--white button--padded" type="button" onClick={history.goBack}>
                                        <span className="icon icon__delete"></span>
                                        <span className="button-text">{t('Allaround.Button.cancel')}</span>
                                    </button>
                                </div>
                            </div>

                        </div>
                        <div className="invoices__container curled-paper settings">

                            <Section sectionTitleKey="Jezik" collapsed={true}>

                                <div className="info__row">
                                    <label className="info__row-name">{t('Jezik')}</label>
                                    <div className="info__row-content">
                                        <div className="info__row-content select-container">
                                            <select onChange={e => setSelectedLanguage(e.target.value)}>
                                                {
                                                    ["cs-cz",
                                                        "de-de",
                                                        "en-gb",
                                                        "es-es",
                                                        "fr-fr",
                                                        "hr-hr",
                                                        "hu-hu",
                                                        "it-it",
                                                        "pl-pl",
                                                        "sr-Latn"].map(l => {
                                                            return <option value={l} selected={selectedLanguage == l}>{t(`Allaround.Language.InvoiceOrOffer.${l}`)}</option>
                                                        })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <CheckboxRow name={`allowSelection`} labelKey="Allow selection of this language" />
                            </Section>

                            <Section sectionTitleKey="Settings.PrintSettings.Table.Invoice.title" >

                                <TextRow name={`tenderTitle`} labelKey="Settings.PrintSettings.Table.Invoice.textWithOfferNumber" placeholderKey="Settings.PrintSettings.Table.Invoice.textWithOfferNumberDefaultText" />

                                <TextRow name={`invoiceTitle`} labelKey="Settings.PrintSettings.Table.Invoice.textWithInvoiceNumber" placeholderKey="Settings.PrintSettings.Table.Invoice.textWithInvoiceNumberDefaultText" />

                                <TextRow name={`invoiceDate`} labelKey="Settings.PrintSettings.Table.Invoice.textWithInvoiceDate" placeholderKey="Settings.PrintSettings.Table.Invoice.textWithInvoiceDateDefaultText" />

                                <TextRow name={`paymentDueDate`} labelKey="Settings.PrintSettings.Table.Invoice.textWithPaymentDate" placeholderKey="Settings.PrintSettings.Table.Invoice.textWithPaymentDateDefaultText" />

                                <TextRow style={{ whiteSpace: " normal!important" }} name={`personResponsible`} labelKey="Settings.PrintSettings.Table.Invoice.namePersonInCharge" placeholderKey="" />

                                <TextRow name={`receiptUserCodeTitle`} labelKey="Settings.PrintSettings.Table.Invoice.textWithOperator" placeholderKey="Settings.PrintSettings.Table.Invoice.textWithOperatorDefaultText" />

                                <TextRow name={`paymentMethod`} labelKey="Settings.PrintSettings.Table.Invoice.textWithWayOfPayment" placeholderKey="Settings.PrintSettings.Table.Invoice.textWithWayOfPaymentDefaultText" />


                                <TextRow as="textarea" rows={6} name={`invoiceValidationText`} labelKey="Settings.PrintSettings.Table.Invoice.textInInvoiceFooter" placeholderKey="" />

                                <TextRow name={`cancellationText`} labelKey="Settings.PrintSettings.Table.Invoice.stornoInvoiceNote" placeholderKey="Settings.PrintSettings.Table.Invoice.stornoInvoiceNoteDefaultText" />

                                <TextRow name="invoiceNotFiscalized" labelKey="Settings.PrintSettings.Table.Invoice.Unfiscalized.infoTitle" placeholderKey="Settings.PrintSettings.Table.Invoice.Unfiscalized.infoText" />

                            </Section>

                            <Section sectionTitleKey='Settings.PrintSettings.Table.Columns.title'>
                                <TextRow name={`columnRowNumber`} labelKey='Settings.PrintSettings.Table.Columns.itemPosition' placeholderKey='Settings.PrintSettings.Table.Columns.itemPositionDefaultText' />

                                <TextRow name={`columnName`} labelKey='Settings.PrintSettings.Table.Columns.item' placeholderKey='Settings.PrintSettings.Table.Columns.itemDefaultText' />

                                <TextRow name={`columnAmount`} labelKey='Settings.PrintSettings.Table.Columns.itemQuantity' placeholderKey='Settings.PrintSettings.Table.Columns.itemQuantityDefaultText' />

                                <TextRow name={`columnUnit`} labelKey='Settings.PrintSettings.Table.Columns.measurementUnit' placeholderKey='Settings.PrintSettings.Table.Columns.measurementUnitDefaultText' />

                                <TextRow name={`columnItemPrice`} labelKey='Settings.PrintSettings.Table.Columns.price' placeholderKey='Settings.PrintSettings.Table.Columns.priceDefaultText' />

                                <TextRow name={`columnRebate`} labelKey='Settings.PrintSettings.Table.Columns.discount' placeholderKey='Settings.PrintSettings.Table.Columns.discountDefaultText' />

                                <TextRow name={`columnFinalItemPrice`} labelKey='Settings.PrintSettings.Table.Columns.totalPrice' placeholderKey='Settings.PrintSettings.Table.Columns.totalPriceDefaultText' />

                            </Section>


                            <Section sectionTitleKey="Settings.PrintSettings.Table.Totals.title">
                                <TextRow name={`columnTotalPrice`} labelKey='Settings.PrintSettings.TableTotalName.totalPrice' placeholderKey='Settings.PrintSettings.TableTotalName.totalPriceDefaultText' />
                                <TextRow name={`columnTax`} labelKey='Settings.PrintSettings.TableTotalName.tax' placeholderKey='Settings.PrintSettings.TableTotalName.taxDefaultText' />
                                <TextRow name={`taxFree`} labelKey='Settings.PrintSettings.TableTotalName.nonTaxable' placeholderKey='Settings.PrintSettings.TableTotalName.nonTaxableDefaultText' />
                                <TextRow name={`columnTotalPriceIncTax`} labelKey='Settings.PrintSettings.TableTotalName.totalPriceWithVAT' placeholderKey='Settings.PrintSettings.TableTotalName.totalPriceWithVATDefaultText' />

                            </Section>

                            <Section sectionTitleKey="Settings.PrintSettings.Table.Contacts.title">
                                <TextRow name={`oib`} labelKey='Settings.PrintSettings.TextContact.VAT' placeholderKey='Settings.PrintSettings.TextContact.VATDefaultText' />
                                <TextRow name={`telephone`} labelKey='Settings.PrintSettings.TextContact.phoneDefaultText' placeholderKey='Settings.PrintSettings.TextContact.phone' />
                                <TextRow name={`fax`} labelKey='Settings.PrintSettings.TextContact.faxDefaultText' placeholderKey='Settings.PrintSettings.TextContact.fax' />
                                <TextRow name={`telephoneAndFax`} labelKey='Settings.PrintSettings.TextContact.telFax' placeholderKey='Settings.PrintSettings.TextContact.telFaxDefaultText' />
                                <TextRow name={`mobile`} labelKey='Settings.PrintSettings.TextContact.mobile' placeholderKey='Settings.PrintSettings.TextContact.mobileDefaultText' />
                                <TextRow name={`email`} labelKey='Settings.PrintSettings.TextContact.mail' placeholderKey='Settings.PrintSettings.TextContact.web' />
                                <TextRow name={`accountNo`} labelKey='Settings.PrintSettings.TextContact.IBAN' placeholderKey='Settings.PrintSettings.TextContact.IBANDefaultText' />

                            </Section>


                        </div>


                        <div className="main__footer">
                            <div className="main__footer-buttons main__footer-buttons--mobile-only">
                                <div className="button-outer">
                                    <button className="button button--white button--padded" type="submit">
                                        <span className="icon icon__save"></span>
                                        <span className="button-text">{t('Allaround.Button.save')}</span>
                                    </button>
                                </div>
                                <div className="button-outer">
                                    <button className="button button--white button--padded" type="button" onClick={history.goBack}>
                                        <span className="icon icon__delete"></span>
                                        <span className="button-text">{t('Allaround.Button.cancel')}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Form>
                }
            </Formik>
        </main >
    </>
}