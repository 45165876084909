import React, { useState } from 'react';
import Modal from './index'
import { useTranslation } from 'react-i18next';

const ConfirmationModalContext = React.createContext({
    open: (titleKey: string, messageKey: string, onConfirm: () => Promise<any>, translationObject?: {}) => { }
})

export const useOfferionModal = () => {
    return React.useContext(ConfirmationModalContext);
}

export const ConfirmationModal = (props: React.PropsWithChildren<any>) => {
    const { t } = useTranslation();
    const [configuration, setConfiguration] = useState<
        {

            titleKey: string,
            messageKey: string,
            onClick: () => Promise<any>,
            translationObject?: {}
        } | null>(null);

    return <ConfirmationModalContext.Provider value={{
        open: (titleKey: string, messageKey: string, onConfirm: () => Promise<any>, translationObject?: {}) => {
            setConfiguration({
                titleKey,
                messageKey,
                onClick: onConfirm,
                translationObject
            })
        }
    }}>
        {configuration && <Modal isOpen={configuration != null}>
            <div className="popup__overlay popup__overlay--show">
                <div className="popup">
                    <div className="popup__close icono-cross" ng-click="cancel()">{t('Allaround.Popup.close')}</div>
                    <div className="popup__icon icon__status-warning--small"></div>
                    <div className="popup__status warning__message">{t(configuration.titleKey)}</div>
                    <div className="popup__message">{t(configuration.messageKey, configuration.translationObject)}</div>

                    <div className="popup__buttons">
                        <button className="button button--gray popup__button" onClick={() => configuration.onClick().then(() => setConfiguration(null))}>{t('Allaround.Button.yes')}</button>
                        <button className="button button--gray popup__button" onClick={() => setConfiguration(null)} >{t('Allaround.Button.no')}</button>
                    </div>
                </div>
            </div>

        </Modal>}

        {props.children}


    </ConfirmationModalContext.Provider>

}