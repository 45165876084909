import React from 'react'

export const SpinnerLoader = () => {
    return (
        <div
            id="spinnerLoader"
            style={{
                display: 'none',
                marginRight: 'auto',
                marginLeft: 'auto',
                position: 'absolute',
                top: '50%',
                left: '50%',
                zIndex: 1000,
            }}>
            <img src="/assets/img/ajax-loader.gif" className="span3" />
        </div>
    )
}
