import React, { ReactNode, useEffect } from 'react';
import { isMobile } from 'is-mobile'
import { createPortal } from 'react-dom';

export const Overlay = ({ children, style }: { children: ReactNode, style?: React.CSSProperties }) => {

    const showGlobalOverlay = isMobile() || (window as any).forceMobile;

    useEffect(() => {

        if (showGlobalOverlay)
            document.body.classList.add("no-scroll");

        return () => {
            if (showGlobalOverlay)
                document.body.classList.remove("no-scroll")
        }
    }, [])

    return showGlobalOverlay
        ? createPortal(<div className="overlay__background overlay__background--visible" style={{ zIndex: 9999 }}>
            <div className="overlay overlay--global overlay--wide" >
                {children}
            </div >
        </div>, document.body)
        : <div className="overlay overlay--wide" style={style}>
            {children}
        </div>
}