
import React, { useState, ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Formik, Field, Form } from 'formik';
import { useHistory, Link, useParams } from 'react-router-dom';
import { OfferionErrorMessage } from '../../shared/forms/validation/OfferionErrorMessage';
import { InlineDateEdit } from '../../shared/forms/InlineDateEdit';
import { ApiClient } from '../../shared/api/api-client';
import { CurrencyField } from '../../shared/forms/CurrencyField';
import { toastSuccess } from '../../shared/toastr';

const Section = ({ sectionTitleKey, children, expanded }: { sectionTitleKey: string, children: ReactNode, additionalButtonsComponent?: React.ComponentType, expanded?: boolean }) => {
    const { t } = useTranslation();

    const [isCollapsed, setIsCollapsed] = useState(!expanded);

    return <>
        <div className="invoices__title invoices__title--short  invoices__title--with-button">
            <h1 style={{ display: "inline", cursor: "pointer" }} onClick={() => setIsCollapsed(!isCollapsed)}>{t(sectionTitleKey)}</h1>
            <button type="button" className="button button--gray right" onClick={() => setIsCollapsed(!isCollapsed)}>
                <span className={`${isCollapsed ? 'icon icon__down-blue' : 'icon icon__up'}`}></span>
            </button>
        </div>

        <div className="table__outer info__additional info__additional--hide" style={{ height: isCollapsed ? "0px" : "auto" }}>
            <div className="info__container">
                {children}
            </div>
        </div>
    </>
}

const LabelWithCheckbox = ({ checkboxName, labelKey, children }: { checkboxName: string, labelKey: string, children?: ReactNode }) => {
    const { t } = useTranslation();

    return <div className="info__row">
        <Field id={checkboxName} name={checkboxName} type="checkbox" />
        <label htmlFor={checkboxName} className="button button--gray checkbox-button">
            <span className="checkbox-button__icon"></span>
        </label>
        <label htmlFor={checkboxName}>{t(labelKey)}{children}</label>
    </div>
}

interface SettingsModel {

    paymentSettings: {

        packageExpiredDate?: Date | null;
        packageExpirationDate: Date;
        packagePaymentDate: Date;
        packagePaid: boolean;
    }

    note: string;
    companyName: string;

}

export const CompanySettingsContainer = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const params = useParams<{ companyId: string }>();
    const [settings, setSettings] = useState<SettingsModel | null>(null)

    useEffect(() => {
        ApiClient.get<SettingsModel>(`/api/AdminCompanySettings/GetSettings/${params.companyId}`).then(setSettings)
    }, [])

    async function onSubmit(settings: SettingsModel) {
        await ApiClient.post('api/AdminCompanySettings/UpdateSettings', settings);

        toastSuccess('Settings Updated');
    }

    if (!settings)
        return null;

    return <>
        <BreadcrumbsItem to={`/company/settings/${params.companyId}`}>{`Settings for ${settings.companyName}`}</BreadcrumbsItem>
        <main className="main">
            <Formik initialValues={settings} onSubmit={onSubmit} >
                {
                    formikProps => {

                        return <Form noValidate={true}>
                            <div className="main__header">
                                <div className="main-title">{`Settings for ${settings.companyName}`}</div>

                                <div className="main__header-buttons">
                                    <div className="button-outer">
                                        <button type="submit" className="button button--white button--padded">
                                            <span className="icon icon__save"></span>
                                            <span className="button-text">{t('Allaround.Button.save')}</span>
                                        </button>
                                    </div>
                                    <div className="button-outer">
                                        <button className="button button--white button--padded" type="button" onClick={history.goBack}>
                                            <span className="icon icon__delete"></span>
                                            <span className="button-text">{t('Allaround.Button.cancel')}</span>
                                        </button>
                                    </div>

                                </div>

                            </div>



                            <div className="invoices__container curled-paper settings">

                                <Section sectionTitleKey='Payment Settings' expanded={true}>

                                    <div className="info__row">
                                        <label className="info__row-name" htmlFor="">Number of invoices in package</label>
                                        <div className="info__row-content info__row-content--with-button">
                                            <div className="">
                                                <Field name="paymentSettings.numberOfReceiptsInPackage" type="number" />
                                            </div>
                                        </div>
                                        <OfferionErrorMessage name="paymentSettings.numberOfReceiptsInPackage" />
                                    </div>
                                    <div className="info__row">
                                        <label className="info__row-name" htmlFor="">Number of offers in package</label>
                                        <div className="info__row-content info__row-content--with-button">
                                            <div className="">
                                                <Field name="paymentSettings.numberOfTendersInPackage" type="number" />
                                            </div>
                                        </div>
                                        <OfferionErrorMessage name="paymentSettings.numberOfTendersInPackage" />
                                    </div>
                                    <div className="info__row">
                                        <label className="info__row-name" htmlFor="">Number of users in package</label>
                                        <div className="info__row-content info__row-content--with-button">
                                            <div className="">
                                                <Field name="paymentSettings.numberOfUsersInPackage" type="number" />
                                            </div>
                                        </div>
                                        <OfferionErrorMessage name="paymentSettings.numberOfUsersInPackage" />
                                    </div>
                                    <div className="info__row">
                                        <label className="info__row-name" htmlFor="">Number of sale places in package</label>
                                        <div className="info__row-content info__row-content--with-button">
                                            <div className="">
                                                <Field name="paymentSettings.numberOfSalePlacesInPackage" type="number" />
                                            </div>
                                        </div>
                                        <OfferionErrorMessage name="paymentSettings.numberOfSalePlacesInPackage" />
                                    </div>

                                    <div className="info__row">
                                        <label className="info__row-name" htmlFor="">Package payment date</label>
                                        <div className="info__row-content info__row-content--with-button">
                                            <div className="">
                                                <InlineDateEdit
                                                    className="offerion-date"
                                                    placeholderKey="Select registration date"
                                                    currentValue={formikProps.values.paymentSettings.packagePaymentDate}
                                                    onSave={({ newValue }) => formikProps.setFieldValue("paymentSettings.packagePaymentDate", newValue)} />
                                            </div>
                                        </div>
                                        <OfferionErrorMessage name="paymentSettings.packagePaymentDate" />
                                    </div>

                                    <div className="info__row">
                                        <label className="info__row-name" htmlFor="">Package end date</label>
                                        <div className="info__row-content info__row-content--with-button">
                                            <div className="">
                                                <InlineDateEdit
                                                    className="offerion-date"
                                                    placeholderKey="Select package end date"
                                                    currentValue={formikProps.values.paymentSettings.packageExpirationDate}
                                                    onSave={({ newValue }) => formikProps.setFieldValue("paymentSettings.packageExpirationDate", newValue)} />
                                            </div>
                                        </div>
                                        <OfferionErrorMessage name="paymentSettings.packageExpirationDate" />
                                    </div>

                                    <div className="info__row">
                                        <label className="info__row-name" htmlFor="">Package expired date</label>
                                        <div className="info__row-content info__row-content--with-button">
                                            <div className="">
                                                <InlineDateEdit
                                                    className="offerion-date"
                                                    placeholderKey="Select package expired date"
                                                    currentValue={formikProps.values.paymentSettings.packageExpiredDate}
                                                    onSave={({ newValue }) => formikProps.setFieldValue("paymentSettings.packageExpiredDate", newValue)} />
                                            </div>
                                        </div>
                                        <OfferionErrorMessage name="paymentSettings.packageExpiredDate" />
                                    </div>

                                    <div className="info__row print-active">
                                        <label className="info__row-name" htmlFor="packageName">Package name</label>
                                        <div className="info__row-content info__row-content--with-button">
                                            <span className="input__info-icon-holder input__wrapper--icon-inside info__input--with-button">
                                                <Field as="select" className="offerion-select" name="paymentSettings.packageName">
                                                    <option value="">---  Please select   ---</option>
                                                    <option value="besplatno">Besplatno</option>
                                                    <option value="osnovno">Osnovno</option>
                                                    <option value="preporuceno">Preporuceno</option>
                                                    <option value="neograniceno">Neograniceno</option>
                                                    <option value="posebno">Posebno</option>
                                                </Field>

                                            </span>
                                        </div>
                                    </div>

                                    <div className="info__row">
                                        <Field id={"paymentSettings.packagePaid"} name={"paymentSettings.packagePaid"} type="checkbox" onChange={(e: any) => {

                                            formikProps.setValues({
                                                ...formikProps.values,
                                                paymentSettings: {
                                                    ...formikProps.values.paymentSettings,
                                                    packagePaid: e.target.checked,
                                                    packageExpiredDate: e.target.checked ? null : formikProps.values.paymentSettings.packageExpiredDate
                                                }
                                            })

                                        }} />
                                        <label htmlFor={"paymentSettings.packagePaid"} className="button button--gray checkbox-button">
                                            <span className="checkbox-button__icon"></span>
                                        </label>
                                        <label htmlFor={"paymentSettings.packagePaid"}>Package paid</label>
                                    </div>

                                    <div className="info__row">
                                        <label className="info__row-name" htmlFor="">Package paid price</label>
                                        <div className="info__row-content info__row-content--with-button">
                                            <div className="">
                                                <Field name="paymentSettings.paidAmount" component={CurrencyField} />
                                            </div>
                                        </div>
                                        <OfferionErrorMessage name="paymentSettings.paidAmount" />
                                    </div>


                                    <div className="info__row">
                                        <label className="info__row-name" htmlFor="">Promo code</label>
                                        <div className="info__row-content info__row-content--with-button">
                                            <div className="">
                                                <Field name="paymentSettings.promoCode" type="text" />
                                            </div>
                                        </div>
                                        <OfferionErrorMessage name="paymentSettings.promoCOde" />
                                    </div>

                                    <div className="info__row">
                                        <label className="info__row-name" htmlFor="">Note</label>
                                        <div className="info__row-content info__row-content--with-button">
                                            <div className="">
                                                <Field as="textarea" name="note" rows={2} />
                                            </div>
                                        </div>
                                        <OfferionErrorMessage name="note" />
                                    </div>
                                </Section>

                                <Section sectionTitleKey="Fiscalization">

                                    <LabelWithCheckbox labelKey="Fiscalization allowed" checkboxName="fiscalizationSettings.isFiscalizationModuleEnabled" />

                                </Section>

                                <Section sectionTitleKey="Email">

                                    <LabelWithCheckbox labelKey="SparkPost Enabled" checkboxName="companyFeatures.sendEmailUsingSparkPost" />

                                    <div className="info__row">
                                        <label className="info__row-name" htmlFor="">Email Configuration</label>
                                        <div className="info__row-content info__row-content--with-button">
                                            <div className="">
                                                <Field as="textarea" name="companyFeatures.config" rows={4} />
                                            </div>
                                        </div>
                                        <OfferionErrorMessage name="companyFeatures.config" />
                                    </div>
                                </Section>
                            </div>

                            <div className="main__footer">
                                <div className="main__footer-buttons main__footer-buttons--mobile-only">
                                    <div className="button-outer">
                                        <button type="submit" className="button button--white button--padded">
                                            <span className="icon icon__save"></span>
                                            <span className="button-text">{t('Allaround.Button.save')}</span>
                                        </button>
                                    </div>
                                    <div className="button-outer">
                                        <button className="button button--white button--padded" type="button" onClick={history.goBack}>
                                            <span className="icon icon__delete"></span>
                                            <span className="button-text">{t('Allaround.Button.cancel')}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    }}
            </Formik>
        </main>
    </>
}