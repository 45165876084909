import React from 'react';
import { Overlay } from '../../shared/elements/Overlay'
import { Formik, Form, Field, FieldArray } from 'formik'
import { useTranslation } from 'react-i18next';
import { OfferionTooltip } from '../../shared/tooltip';
import * as Yup from 'yup';
import { OfferionErrorMessage } from '../../shared/forms/validation/OfferionErrorMessage';

interface Task {
    name: string;
    mailTextAsPlainText: string;
    mailTextAsHtml: string;
    subject: string;
    mailTo: string;
    mailFrom: string;
    mailDisplayName: string;
    conditionGroups: any[];
    type: number;
    snovioList: string;
}


interface Props {
    onSubmit: (task: {}) => Promise<void>;
    closeOverlay: () => void;
    task: Task | null
}

export const TasksForm = (props: Props) => {
    const { t } = useTranslation();

    const schema = Yup.object().shape({
        name: Yup.string()
            .required("This field is required"),
        conditionGroups: Yup.array().of(

            Yup.array().of(
                Yup.object().shape({
                    operator: Yup.string().required("Operator is required"),
                    value: Yup.string().when(['operator'], (operator: string, schema: Yup.NumberSchema) => {

                        if (operator == "EMPTY" || operator == "NOT-EMPTY")
                            return schema;

                        return schema.required("Value is required");
                    }),
                })
            )

        )
    });

    function copyToClipBoard(text: string) {
        navigator.clipboard.writeText(text);
    }

    return <Overlay style={{ width: "800px" }}>
        <h1 className="overlay__title">
            {props.task ? 'Edit Task' : 'Add Task'}
        </h1>

        <button className="button overlay__close" onClick={props.closeOverlay}>
            <span className="icono-cross"></span>
        </button>

        <Formik initialValues={props.task || {
            name: "",
            type: 0,
            mailTextAsPlainText: "",
            mailTextAsHtml: "",
            subject: "",
            mailTo: "",
            mailFrom: "",
            mailDisplayName: "",
            snovioList: "",
            conditionGroups: []
        }}
            validationSchema={schema}
            onSubmit={props.onSubmit}>

            {formikProps => <Form noValidate={true} autoComplete="off">
                <div>
                    <div className="info__container">
                        <div className="info__row">
                            <label className="info__row-name" htmlFor="name">Name</label>
                            <div className="info__row-content">
                                <Field id="name" type="text"
                                    name="name" />
                                <OfferionErrorMessage name="name" />
                            </div>
                        </div>

                        <div className="info__row print-active">
                            <label className="info__row-name" htmlFor="packageName">Type</label>
                            <div className="info__row-content info__row-content--with-button">
                                <span className="input__info-icon-holder input__wrapper--icon-inside info__input--with-button">
                                    <Field as="select" className="offerion-select" name="type">
                                        <option value="0">Send Mail</option>
                                        <option value="1">Add to Snov.io list</option>
                                    </Field>
                                </span>
                            </div>
                        </div>

                        {formikProps.values.type == 0 && <>
                            <div className="info__row">
                                <label className="info__row-name" htmlFor="mailTo">Mail To</label>
                                <div className="info__row-content">
                                    <Field id="mailTo" type="text" name="mailTo" />
                                </div>
                                <OfferionTooltip placement="right" titleKey='Email Sending' bodyKey="If entered, email will be sent only to this address" />
                            </div>

                            <div className="info__row" style={{ display: "flex", alignItems: "center" }}>
                                <label className="info__row-content" style={{ flexGrow: 1 }} htmlFor="sendToFirstAdmin">Send To First Admin
                                </label>
                                <div className="info__row-small" style={{ marginRight: "16px" }}>
                                    <Field id="sendToFirstAdmin" type="checkbox"
                                        className="checkbox-button--no-label"
                                        name="sendToFirstAdmin" />
                                    <label htmlFor="sendToFirstAdmin"
                                        className="button button--gray checkbox-button right">
                                        <span className="checkbox-button__icon"></span>
                                    </label>
                                </div>
                                <OfferionTooltip iconStyle={{ left: "-4px", position: "relative" }} placement="right" titleKey='Email Sending' bodyKey="This is applicable only if previous field is not set" />

                            </div>

                            <div className="info__row">
                                <label className="info__row-name" htmlFor="mailFrom">Mail From</label>
                                <div className="info__row-content">
                                    <Field id="mailFrom" type="text" name="mailFrom" />
                                </div>
                                <OfferionTooltip placement="right" titleKey='Optional' bodyKey="This field is optional" />
                            </div>

                            <div className="info__row">
                                <label className="info__row-name" htmlFor="mailDisplayName">Mail Display Name</label>
                                <div className="info__row-content">
                                    <Field id="mailDisplayName" type="text" name="mailDisplayName" />
                                </div>
                                <OfferionTooltip placement="right" titleKey='Optional' bodyKey="This field is optional" />
                            </div>

                            <div className="info__row">
                                <label className="info__row-name" htmlFor="subject">Subject</label>
                                <div className="info__row-content">
                                    <Field id="subject" type="text" name="subject" />
                                    <OfferionErrorMessage name="subject" />

                                </div>
                            </div>

                            <div className="info__row">
                                <label className="info__row-name" htmlFor="mailTextAsHtml">Mail Text as Html</label>
                                <div className="info__row-content">
                                    <Field id="mailTextAsHtml" as="textarea" rows={3} name="mailTextAsHtml" />
                                    <OfferionErrorMessage name="mailTextAsHtml" />
                                </div>
                            </div>


                            <div className="info__row" style={{ color: "black", fontSize: "16px" }}>
                                PLACEHOLDERI KOJI SE MOGU KORISTITI U MAILU
                                <div><span style={{ margin: "10px" }} onClick={e => copyToClipBoard('{{FIRST_NAME}}')}>{"{{ FIRST_NAME }}"}</span>  Osobe kojoj se šalje (prazno ako se šalje na „naš“ mail) </div>
                                <div><span style={{ margin: "10px" }} onClick={e => copyToClipBoard('{{FIRST_NAME_EXYU}}')}>{"{{ FIRST_NAME_EXYU}}"} </span>  Da se može napisati “Pozdrav Ivane” - ovo automatski dodaje nastavak kad treba </div>
                                <div><span style={{ margin: "10px" }} onClick={e => copyToClipBoard('{{LAST_NAME}}')}>{"{{ LAST_NAME }}"} </span> Osobe kojoj se šalje (prazno ako se šalje na „naš“ mail) </div>
                                <div><span style={{ margin: "10px" }} onClick={e => copyToClipBoard('{{RECEIPTS_COUNT}}')}>{"{{ RECEIPTS_COUNT }}"}</span>  Broj računa za dani filter </div>
                                <div><span style={{ margin: "10px" }} onClick={e => copyToClipBoard('{{TENDERS_COUNT}}')}>{"{{ TENDERS_COUNT }}"}  </span> Broj ponuda za dani filter </div>
                                <div><span style={{ margin: "10px" }} onClick={e => copyToClipBoard('{{PACKAGE}}')}>{"{{ PACKAGE }}"} </span>  Naziv paketa </div>
                                <div><span style={{ margin: "10px" }} onClick={e => copyToClipBoard('{{PACKAGE_PAID_AMOUNT}}')}>{"{{ PACKAGE_PAID_AMOUNT }}"} </span>  Koliko je plaćen paket </div>
                                <div><span style={{ margin: "10px" }} onClick={e => copyToClipBoard('{{RECEIPTS_IN_PACKAGE}}')}>{"{{ RECEIPTS_IN_PACKAGE }}"}</span>   Broj računa u paketu </div>
                                <div><span style={{ margin: "10px" }} onClick={e => copyToClipBoard('{{TENDERS_IN_PACKAGE}}')}>{"{{ TENDERS_IN_PACKAGE }}"} </span>  Broj ponuda u paketu </div>
                                <div><span style={{ margin: "10px" }} onClick={e => copyToClipBoard('{{PACKAGE_EXPIRATION_DATE}}')}>{"{{ PACKAGE_EXPIRATION_DATE }}"} </span>  Datum isteka paketa ili probnog perioda </div>
                                <div><span style={{ margin: "10px" }} onClick={e => copyToClipBoard('{{PACKAGE_EXPIRED_DATE}}')}>{"{{ PACKAGE_EXPIRED_DATE }}"} </span>  Datum kad je istekao paket ili probni period </div>
                            </div>

                            <div className="info__row">
                                <label className="info__row-name" htmlFor="mailTextAsPlainText">Mail Text as Plain Text</label>
                                <div className="info__row-content">
                                    <Field id="mailTextAsPlainText" as="textarea" rows={3} name="mailTextAsPlainText" />
                                    <OfferionErrorMessage name="mailTextAsPlainText" />
                                </div>
                            </div>
                        </>}

                        {formikProps.values.type == 1 && <>
                            <div className="info__row">
                                <label className="info__row-name" htmlFor="snovioList">Snov.io list</label>
                                <div className="info__row-content">
                                    <Field id="snovioList" type="text" name="snovioList" />
                                </div>
                            </div>
                        </>}

                        <div className="info__row">
                            <label className="info__row-name" htmlFor="description">Description</label>
                            <div className="info__row-content">
                                <Field id="description" as="textarea" rows={2} name="description" />
                            </div>
                        </div>

                        <div className="info__row">
                            <label className="info__row-name" htmlFor="aditionalData">Companies Sent</label>
                            <div className="info__row-content">
                                <Field id="aditionalData" as="textarea" rows={3} name="aditionalData" />
                            </div>
                        </div>

                        <div className="info__row conditions-container">
                            <FieldArray name="conditionGroups" >
                                {
                                    groupsHelper => {
                                        return <>
                                            <div className="new-button-container">
                                                <button
                                                    onClick={() => groupsHelper.push([])}
                                                    type="button"
                                                    className="button button--white catalogue__button"
                                                    style={{ padding: "0px 8px" }}>
                                                    <span className="icon icon__plus"></span>
                                                    <span className="catalogue__button-text button-text--right">New Condition Group</span>
                                                </button>
                                            </div>
                                            <div className="condition-groups">
                                                {formikProps.values.conditionGroups.map((conditionGroup: any[], groupIndex: number) => {
                                                    return <FieldArray name={`conditionGroups.${groupIndex}`}>
                                                        {groupHelper =>
                                                            <>
                                                                <div className="condition-group-container">
                                                                    <div className="new-button-container">
                                                                        <button
                                                                            onClick={() => groupHelper.push({
                                                                                field: "Settings.PaymentSettings.PackageExpirationDate",
                                                                                operator: "GT",
                                                                                value: "0"
                                                                            })}
                                                                            type="button"
                                                                            className="button button--white catalogue__button"
                                                                            style={{ padding: "0px 8px", marginRight: "10px" }}>
                                                                            <span className="icon icon__plus"></span>
                                                                            <span className="catalogue__button-text button-text--right">New Condition</span>
                                                                        </button>

                                                                        <button
                                                                            onClick={() => groupsHelper.remove(groupIndex)}
                                                                            type="button"
                                                                            className="button button--white catalogue__button"
                                                                            style={{ padding: "0px 8px" }}>
                                                                            <span className="icon icon__plus"></span>
                                                                            <span className="catalogue__button-text button-text--right">Remove group</span>
                                                                        </button>
                                                                    </div>
                                                                    {
                                                                        conditionGroup.map((condition, itemIndex) => {
                                                                            return <div className="condition-group">
                                                                                <div className="condition-item">
                                                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                                                        <div className="condition-remove">
                                                                                            <button
                                                                                                onClick={() => groupHelper.remove(itemIndex)}
                                                                                                type="button"
                                                                                                className="button button--white catalogue__button">
                                                                                                <span className="catalogue__button-text">X</span>
                                                                                            </button>
                                                                                        </div>
                                                                                        <div className="condition-field">
                                                                                            <Field as="select" name={`conditionGroups.${groupIndex}.${itemIndex}.field`}

                                                                                                onChange={(e: any) => {
                                                                                                    formikProps.setFieldValue(`conditionGroups.${groupIndex}.${itemIndex}.field`, e.target.value);
                                                                                                    formikProps.setFieldValue(`conditionGroups.${groupIndex}.${itemIndex}.operator`, "");
                                                                                                    formikProps.setFieldValue(`conditionGroups.${groupIndex}.${itemIndex}.value`, "");
                                                                                                }}
                                                                                            >
                                                                                                <option value="Settings.PaymentSettings.PackageExpirationDate">Package Expiration Date</option>
                                                                                                <option value="Settings.PaymentSettings.PackageExpiredDate">Package Expired Date</option>
                                                                                                <option value="Settings.PaymentSettings.PackagePaymentDate">Package Payment Date</option>
                                                                                                <option value="Settings.PaymentSettings.PackagePaid">Package Paid</option>
                                                                                                <option value="CreatedTimestamp">Created Date</option>
                                                                                                <option value="Type">Company Type</option>
                                                                                            </Field>
                                                                                        </div>
                                                                                        <div className="condition-operator">
                                                                                            <Field as="select" style={{ minWidth: "170px" }} name={`conditionGroups.${groupIndex}.${itemIndex}.operator`}>
                                                                                                <option value="">-- Choose Operator --</option>

                                                                                                {condition.field == "Type" || condition.field == "Settings.PaymentSettings.PackagePaid" ?
                                                                                                    <option value="EQUAL">Is</option>
                                                                                                    : <>
                                                                                                        <option value="GT">Greater Than</option>
                                                                                                        <option value="GTE">Greater Than or Equal</option>
                                                                                                        <option value="LT">Less Than</option>
                                                                                                        <option value="LTE">Less Than or equal</option>
                                                                                                        <option value="EMPTY">Is Empty</option>
                                                                                                        <option value="NOT-EMPTY">Is Not Empty</option>
                                                                                                    </>}
                                                                                            </Field>
                                                                                        </div>
                                                                                    </div>
                                                                                    {condition.field == "Type" ?
                                                                                        <>
                                                                                            <Field as="select" name={`conditionGroups.${groupIndex}.${itemIndex}.value`}>
                                                                                                <option value="">-- Select Value --</option>
                                                                                                <option value="0">Basic</option>
                                                                                                <option value="1">Lawyer</option>
                                                                                                <option value="2">Window Maker</option>
                                                                                                <option value="3">Renter</option>
                                                                                                <option value="4">Other</option>
                                                                                            </Field>
                                                                                        </>
                                                                                        :
                                                                                        condition.field == "Settings.PaymentSettings.PackagePaid"

                                                                                            ?
                                                                                            <>
                                                                                                <Field as="select" name={`conditionGroups.${groupIndex}.${itemIndex}.value`}>
                                                                                                    <option value="">-- Select Value --</option>
                                                                                                    <option value="True">True</option>
                                                                                                    <option value="False">False</option>
                                                                                                </Field>
                                                                                            </>

                                                                                            :

                                                                                            <>
                                                                                                {
                                                                                                    condition.operator == "EMPTY" || condition.operator == "NOT-EMPTY"
                                                                                                        ? null
                                                                                                        : <>
                                                                                                            <div className="condition-value">
                                                                                                                <Field type="text" name={`conditionGroups.${groupIndex}.${itemIndex}.value`} /> days
                                                                                                            </div>
                                                                                                            <div className="condition-unit">
                                                                                                                from now *
                                                                                                            </div>
                                                                                                        </>
                                                                                                }

                                                                                            </>
                                                                                    }
                                                                                </div>
                                                                                {
                                                                                    itemIndex < conditionGroup.length - 1 && <div className="condition-item-separator">
                                                                                        AND
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        })
                                                                    }

                                                                </div>
                                                                {
                                                                    groupIndex < formikProps.values.conditionGroups.length - 1 && <div className="condition-item-separator">
                                                                        OR
                                                                    </div>
                                                                }
                                                            </>
                                                        }
                                                    </FieldArray>
                                                })}
                                            </div>
                                        </>
                                    }
                                }
                            </FieldArray>
                        </div>
                    </div>

                    <div className="button-group bottom-controls">
                        <button className="button button--gray button--padded" disabled={formikProps.isSubmitting} type="submit">
                            <span className="icon icon__check-green"></span>
                            <span className="button-text button-text--always-show">{t('Allaround.Button.save')}</span>
                        </button>
                        <button className="button button--gray button--padded" type="button" onClick={props.closeOverlay}>
                            <span className="icon icon--small icon__cross-red"></span>
                            <span className="button-text button-text--always-show">{t('Allaround.Button.cancel')}</span>
                        </button>
                    </div>

                    <br />
                </div>
            </Form >}
        </Formik >

    </Overlay >
}