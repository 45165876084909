const defaultState = {
    accessToken: '',
    authenticated: false,
}

interface UserAuthenticatedAction {
    type: 'USER_AUTHENTICATED'
    user: { accessToken: string, authenticated: boolean }
}

interface UserLoggedOutAction {
    type: 'USER_LOGGED_OUT'
}

type KnownAction = UserAuthenticatedAction | UserLoggedOutAction

export function UserReducer(state = defaultState, action: KnownAction) {

    switch (action.type) {
        case 'USER_AUTHENTICATED':
            return {
                ...action.user,
            }

        case 'USER_LOGGED_OUT':
            return defaultState;

        default:
            return state
    }
}
