export const croatian = {
	"Invoice.Edit.additionalInformation":"Dodatne informacije",
	"Settings.PrintSettings.TableTotalName.tax":"Naziv poreza",
	"Settings.Table.PredefinedText.title":"Predefinirani tekstovi",
	"Clients.AgreedPrices.Edit.discountPlaceHolder":"Unesite dogovoreni popust",
	"Allaround.PaymentType.cash.shortcode":"G",
	"Settings.PrintSettings.Table.Pictures.footerFreeNote":"U besplatnoj verziji nije dozvoljeno mijenjati podnožje.",
	"Settings.PrintSettings.Table.Invoice.Unfiscalized.infoTitle":"Napomena na računu koji je trebao biti fiskaliziran, a nije",
	"Settings.Users.Edit.loginMailPlaceholder":"primjer@gmail.com (email pomoću kojeg će ulaziti u program)",
	"Settings.Users.Edit.saveAndSend":"Pošalji korisniku podatke na mail",
	"Settings.Fiscalisation.Messages.errorActivationMissingCompanyCity":"grad tvrtke",
	"Settings.SalesPlaces.Edit.Table.streetNumber":"Broj ulice",
	"Offers.Side.day":"Dan (svi)",
	"Allaround.Currency.HRK.shortcode":"kn",
	"Settings.PrintSettings.Table.Pictures.signature":"Potpis",
	"Allaround.Currency.CHF.shortcode":"Fr",
	"Dashboard.amount":"Iznos",
	"Settings.PrintSettings.Table.Invoice.textWithInvoiceNumberDefaultText":"Račun broj",
	"Invoice.Print.Fiscalisation.messageNoRights":"Ovaj račun potrebno je fiskalizirati prije ispisa ili slanja. Vaša korisnička prava ne dopuštaju fiskalizaciju računa. Molimo kontaktirajte vašeg administratora.",
	"Invoice.Table.Client.Menu.select":"Odaberi klijenta",
	"Templates.Header.pageTitle":"Predlošci",
	"Settings.PrintSettings.Table.Pictures.NewHeader.notPictureNote":"Datoteka nije slika.",
	"Invoices.Side.day":"Dan (svi)",
	"Settings.SalesPlaces.Edit.Table.zipCodePlaceholder":"Unesi poštanski broj",
	"Clients.AgreedPrices.Select.pageTitle":"Odaberi",
	"Allaround.PaymentType.bankAccount.shortcode":"T",
	"Invoice.Edit.Message.WarningCancelledInvoice":"Ovaj račun je storniran i ne može se mijenjati niti brisati.",
	"Clients.AgreedPrices.Side.deleteAllPrices":"Obriši sve dogovorene cijene",
	"Invoice.Header.Menu.invoiceSettings":"Postavke računa",
	"PriceList.EditGroup.Message.successUpdate":"Grupa uspješno spremljena.",
	"Clients.Table.Header.title":"Popis klijenata",
	"Allaround.PaymentType.check.shortcode":"C",
	"Dashboard.client":"Klijent",
	"Settings.PrintSettings.Table.Invoice.stornoInvoiceNote":"Napomena uz storno računa",
	"Invoice.SaveOffer.Message.errorUpdate":"Greška prilikom spremanja podataka o ponudi.",
	"Invoice.Table.TemplateName.title":"Naziv predloška",
	"Allaround.CompanyType.windowMaker":"Prodavatelj/proizvođač prozora i vrata",
	"PriceList.EditGroup.pictureDelete":"Obriši",
	"Settings.Fiscalisation.Messages.errorActivationMissingCompanyAddress":"adresu tvrtke",
	"Settings.SalesPlaces.Table.Header.city":"Mjesto",
	"Settings.PrintSettings.Table.Columns.priceDefaultText":"CIJENA",
	"Settings.SalesPlaces.Table.Header.name":"Naziv prodajnog mjesta",
	"Clients.AgreedPrices.Side.Delete.Message.successDelete":"Uspješno ste obrisali sve dogovorene cijene.",
	"Settings.Users.Edit.identificationNumber":"OIB",
	"Templates.Table.ItemMenu.edit":"Uredi",
	"Invoice.ChangeAllPrices.pageTitle":"Promjena svih cijena",
	"Allaround.Message.packageThresholdReachedTwofold":"Možete napraviti još <b>{{numOffersLeft}} {{offerSingular}}</b> i <b>{{numInvoicesLeft}} {{invoiceSingular}}</b> u ovom mjesecu. Napravili ste <b>{{numOffers}}</b> od <b>{{numOffersInPackage}}</b> {{offerPlural}} i <b>{{numInvoces}}</b> od <b>{{numInvoicesInPackage}}</b> {{invoicePlural}} u vašem paketu. Za pogledati uvjete prelaska na veći paket <a href=\\\"http://www.izracunko.com/cijene#nadoplata\\\" target=\\\"_blank\\\">kliknite ovdje</a>.",
	"Settings.MyPictures.Table.Header.mail":"Login E-Mail",
	"Settings.MyPictures.Table.Header.user":"Korisnik",
	"Invoice.EditCurrency.exchangeRate":"Tečaj",
	"Footer.termsOfService":"Uvjeti korištenja",
	"Clients.AgreedPrices.Select.select":"Odaberi",
	"Invoice.Edit.quantity":"Količina",
	"Clients.AgreedPrices.AddProductOrGroup.search":"Pretraživanje",
	"Templates.Table.emptySearchMessage":"Predložak kakvog ste tražili nije pronađen. \\r\\n\\r\\nMožete ili promijeniti pretragu ili napraviti novi predložak.",
	"Invoice.DeleteInvoice.Message.SuccessDelete":"Uspješno ste obrisali račun.",
	"PriceList.Import.Table.groupTitle":"Naziv grupe",
	"PriceList.EditProduct.product":"Proizvod",
	"Settings.Users.Edit.loginMail":"Login e-mail",
	"Settings.MyPictures.Messages.warningTooManySelected":"Možete odabrati samo jednu sliku za dodavanje.",
	"Invoice.Table.headerNone":"Dodajte ovdje vaše zaglavlje",
	"Offers.Side.buttonSearch":"Traži",
	"PriceList.EditGroup.Message.errorCreate":"Greška prilikom spremanja grupe.",
	"Dashboard.newInvoiceButton":"Novi račun",
	"Offers.Header.templates":"Predlošci",
	"Settings.MyPictures.ItemMenu.delete":"Obriši",
	"Invoice.CancelInvoice.questionInfo":"Kada račun stornirate, više ga nećete moći mijenjati. Ujedno, napraviti će se novi račun, koji će imati sve isto kao i ovaj račun, samo što će biti negativan. Na taj način ova dva računa se poništavaju i završeno je storniranje računa.",
	"Clients.Edit.cellphonePlaceholder":"Unesi broj mobitela",
	"Allaround.Currency.USD.shortcode":"$",
	"Settings.Users.Edit.invoicesAlowed":"Korisnik može raditi račune",
	"Reports.Type.invoicesBook":"Knjiga računa",
	"Invoice.Table.Header.name":"Naziv",
	"Settings.SalesPlaces.Delete.successDelete":"Uspješno ste obrisali prodajno mjesto {{salesPlaceNameAddress}}.",
	"Allaround.Currency.GBP.shortcode":"£",
	"Invoice.Send.additionalRecipientsInfo":"Ukoliko želite poslati mail na više e-mail adresa, trebate ih odvojiti zarezom.",
	"Allaround.Validation.number":"{{fieldName}} mora biti broj.",
	"PriceList.Table.ItemMenu.delete":"Obriši",
	"Settings.SalesPlaces.Header.pageTitle":"Prodajna mjesta",
	"Offers.Header.newOffer":"Nova ponuda",
	"PriceList.EditProduct.pictureNew":"Dodaj sliku",
	"Settings.Table.Contact.web":"Web",
	"Settings.Table.Contact.fax":"Fax",
	"Settings.Table.TaxCurrency.changeVATperProduct":"Dopusti mijenjanje PDV-a svake stavke",
	"Clients.AgreedPrices.AddProductOrGroup.Message.errorAdd":"Novi uvjeti nisu uspješno dodani.",
	"Settings.Side.printerSettings":"Postavke ispisa",
	"PriceList.Side.searchButton":"Traži",
	"Allaround.Validation.alphanumeric":"{{fieldName}} mora sadržavati isključivo slova engleske abecede i brojeve.",
	"Invoice.Cancel.Message.success":"Promjene podataka o računu nisu spremljene.",
	"Settings.MyPictures.Messages.successDelete":"Slika uspješno obrisana.",
	"Login.pageTitle":"Prijava",
	"Clients.Edit.pageTitleNew":"Unesi klijenta",
	"Invoice.Table.Footer.remarkPlaceholderOffer":"Ovdje možete unijeti napomenu koja će biti vidljiva na ponudi. Npr. o uvjetima plaćanja, predujmu, … ili neku obaveznu napomenu koju vam zakon nameće.",
	"Invoice.Send.messageSuccess":"Mail uspješno poslan",
	"PriceList.DeleteProduct.pageTitle":"Obriši proizvod",
	"Templates.Table.Header.title":"Popis predložaka",
	"Settings.Users.Table.ItemMenu.delete":"Obriši",
	"PriceList.Import.Table.fileButton":"Odaberi datoteku",
	"PriceList.EditProduct.pictureDelete":"Obriši",
	"Invoice.Print.Fiscalisation.buttonPrint":"Ispiši bez fiskalizacije",
	"Clients.AgreedPrices.Delete.Product.questionYesNo":"Da li zaista želite obrisati dogovorenu cijenu za proizvod '{{productName}}'?",
	"Settings.PrintSettings.Message.successUpdate":"Postavke ispisa uspješno spremljene.",
	"Settings.Users.Header.newUser":"Dodaj korisnika",
	"Settings.Table.TaxCurrency.predefinedInvoiceCurrency":"Valuta računa",
	"Allaround.PaymentType.cash.title":"Novčanice",
	"Login.popupErrorWrongUser":"Nismo pronašli korisnika s podacima koje ste unijeli. Provjerite unesene podatke i ponovno se prijavite ili odaberite 'Zaboravili ste lozinku?' kako bi promijenili lozinku.",
	"Invoice.EditOffer.picture":"Slika proizvoda",
	"Settings.SalesPlaces.Edit.Table.countyPlaceholder":"Unesi naziv općine",
	"Invoice.SendEInvoice.purchaseOrderNum":"Broj narudžbenice",
	"PriceList.EditProduct.VAT":"PDV",
	"Templates.Side.searchTitle":"Pretraživanje",
	"Invoices.Table.Header.date":"Datum",
	"Settings.Table.Pricelist.showGroupsInPrintInfoWindowmakers":"Naziv proizvoda na ispisu se slaže od naziva grupa, podgrupa i naziva proizvoda. Kada je proizvod određen širinom i visinom preporučujemo uključivanje ove opcije. Npr. grupa PROZOR + podgrupa DVOKRILNI + proizvod 1200 x 1200 daje ukupni naziv stavke PROZOR DVOKRILNI 1200 x 1200. Cjenik koji ste dobili napravljen je pod pretpostavkom da je ova opcija uključena.",
	"Clients.AgreedPrices.AddProductOrGroup.pageTitle":"Odaberi iz cjenika",
	"Allaround.Validation.passwordsDontMatch":"Unesene lozinke nisu iste.",
	"Dashboard.Chart.IncomePerMonth.title":"Prodaja po mjesecima",
	"PriceList.EditProduct.taxFreeCheck":"Ovaj proizvod/usluga je oslobođen plaćanja poreza",
	"Settings.Table.TaxCurrency.defaultVATInfo":"Ovaj PDV će automatski biti dodjeljen svakoj novoj stavci u ponudi ili računu.",
	"Settings.Table.Pricelist.showSimilarProductPrices":"Prilikom unosa stavke želim da se prikazuju cijene sličnih proizvoda",
	"Registration.accountdataPassword":"Lozinka",
	"Settings.PrintSettings.Table.Pictures.NewHeader.headerTitle":"Zaglavlje",
	"Settings.SalesPlaces.Edit.Table.invoicePrefix":"Prefiks računa",
	"Invoices.Table.Header.client":"Klijent",
	"Settings.MyPictures.Table.MyPictures.title":"Moje slike",
	"Invoice.Send.sendCopyToYourselfYesNo":"Pošalji kopiju sebi na {{userMail}}",
	"Settings.Users.NewUser.userLabel":"Oznaka korisnika",
	"PriceList.EditProduct.Message.errorCreate":"Greška prilikom spremanja proizvoda.",
	"Offers.Table.Header.tenderNr":"Ponuda broj",
	"Invoice.Header.Menu.createInvoice":"Napravi račun",
	"Settings.Fiscalisation.Table.CurrentSettings.certificateInfoDefaultText":"Nema certifikata",
	"Settings.PrintSettings.Table.Pictures.NewFooter.pageTitleEdit":"Zamijeni podnožje",
	"Popup.deleteTitle":"Želite izbrisati?",
	"Settings.EInvoice.Messages.errorSave":"Neispravna kombinacija korisničkog imena i lozinke za Moj eRačun servis.",
	"Settings.Table.Languages.title":"Jezici",
	"Settings.SalesPlaces.Table.ItemMenu.edit":"Uredi",
	"Invoice.Edit.totalWithoutVAT":"Ukupno:",
	"Invoices.Table.Header.amount":"Iznos",
	"Settings.Users.Delete.questionYesNo":"Da li želite izbrisati korisnika {{userName}}?",
	"Allaround.Message.offerAkuzativ":"ponudu",
	"Dashboard.date":"Datum",
	"Offers.Header.pageTitle":"Ponude",
	"Invoice.Edit.totalVAT":"PDV {{vatPercentage}}:",
	"Invoice.CancelInvoice.questionYesNo":"Da li zaista želite stornirati račun?",
	"Settings.PrintSettings.TextContact.faxDefaultText":"Fax:",
	"Dashboard.Chart.Month.september":"Rujan",
	"Settings.Table.PredefinedText.mailOffer":"Predefinirani mail uz ponudu",
	"Clients.AgreedPrices.Edit.discount":"Popust",
	"Registration.personaldataNamePlaceholder":"Vaše ime",
	"Settings.Table.Pricelist.showPurchasePriceInfoTitle":"Nabavna cijena i marža",
	"Allaround.Button.no":"Ne",
	"Invoice.Header.pageTitleEditInvoice":"Uredi račun",
	"PriceList.EditGroup.name":"Naziv grupe",
	"Settings.Table.Pricelist.showPurchasePriceInfo":"Uključivanjem ove opcije dobit ćete automatsko računanje svoje zarade.",
	"Settings.Table.Address.streetNumber":"Kućni broj",
	"Settings.PrintSettings.Table.Columns.itemQuantityDefaultText":"KOL",
	"Settings.PrintSettings.Table.Invoice.textWithWayOfPaymentDefaultText":"Način plaćanja",
	"Settings.Users.Edit.Message.errorUpdate":"Podaci o korisniku nisu uspješno spremljeni.",
	"Header.reports":"Izvještaji",
	"Dashboard.Chart.SalesPerMonth.title":"Prihod po mjesecima",
	"Invoice.CancelInvoice.invoiceToBeCancelled":"Račun koji se stornira",
	"Settings.PrintSettings.Table.Pictures.NewSignature.signatureChange":"Zamijeni potpis",
	"Login.popupErrorCantConnect":"Ne možemo se spojiti na bazu podataka, molim provjerite vašu internet vezu.",
	"Settings.SalesPlaces.Table.Header.perPage":"po stranici",
	"Settings.Table.BasicInformation.nameInfo":"Službeni naziv firme, obrta, udruge, ... ili vaše osobno ime i prezime ukoliko se bavite nekom slobodnom djelatnošću.",
	"PriceList.Header.addProduct":"Dodaj proizvod",
	"Popup.warningTitle":"Upozorenje",
	"Settings.Users.Edit.Header.pageTitleEdit":"Uredi korisnika",
	"Settings.Users.Edit.companyNumber":"OIB",
	"Settings.SalesPlaces.Delete.infoMessage":"Niste obrisali prodajno mjesto {{salesPlaceNameAddress}}.",
	"Settings.PrintSettings.Table.Columns.discount":"Naziv stupca popusta stavke",
	"Header.settings":"Postavke",
	"Registration.successMessageTitle":"Dobrodošli",
	"Registration.accountdataTypeSelectorPlaceholder":"Odaberite tip subjekta (tvrtke)",
	"Clients.AgreedPrices.Edit.agreedSellPricePlaceholder":"Unesite dogovorenu cijenu",
	"Dashboard.Chart.TopProductsPerIncome.chartTitle":"Proizvodi po prihodu",
	"Allaround.DropDown.Other":"Drugo",
	"PriceList.DeleteGroup.pageTitle":"Obriši grupu",
	"Settings.PrintSettings.TableTotalName.nonTaxable":"Naziv za neoporezivo",
	"Offers.Table.emptyMessageTitle":"Niste napravili još niti jednu ponudu.",
	"Settings.Table.Address.streetPlaceholder":"npr. Vukovarska",
	"Settings.SalesPlaces.Edit.Table.invoiceSufix":"Sufiks računa",
	"Invoice.Edit.priceWithVAT":"Cijena s PDV-om:",
	"Settings.Users.Edit.surname":"Prezime",
	"Allaround.Message.offersAndInvoicesGenitiv":"ponuda i računa",
	"Invoice.SaveOffer.Message.errorCreate":"Greška prilikom spremanja ponude.",
	"Settings.PrintSettings.TableTotalName.totalPriceWithVAT":"Naziv ukupne cijene s porezom",
	"Settings.PrintSettings.TextContact.phoneDefaultText":"Tel:",
	"Allaround.Message.invoicePlural":"računa",
	"Settings.PrintSettings.TextContact.phone":"Tekst uz telefon",
	"Invoice.Edit.priceDifference":"Razlika u cijeni",
	"Clients.AgreedPrices.Edit.Product.Message.errorSave":"Uvjeti za proizvod {{productName}} nisu uspješno spremljeni.",
	"Settings.SalesPlaces.Edit.Table.workingTime":"Radno vrijeme",
	"Registration.accountdataNamePlaceholder":"Naziv onoga tko izdaje račun (naziv tvrtke ili obrta ili vaše ime i prezime)",
	"Settings.PrintSettings.Table.Columns.item":"Naziv stupca stavke",
	"Allaround.Validation.discountMoreThan100":"Popust ne može biti veći od 100%.",
	"Invoices.Table.Mobile.date":"Datum",
	"Invoice.Table.Operator.title":"Operater",
	"Settings.SalesPlaces.emptySearchMessage":"Prodajno mjesto kakvo ste tražili nije pronađeno. \\r\\n\\r\\nMožete ili promijeniti pretragu ili napraviti novo prodajno mjesto.",
	"Invoice.Edit.totalPrice":"Sveukupna cijena",
	"Allaround.PaymentType.creditCard.title":"Kartica",
	"Dashboard.Chart.Month.december":"Prosinac",
	"Dashboard.Chart.Month.june":"Lipanj",
	"Dashboard.Chart.Month.july":"Srpanj",
	"Dashboard.tenderNumber":"Tender Number",
	"Clients.Delete.Message.successDelete":"Uspješno ste obrisali klijenta {{clientName}}.",
	"Invoice.Header.pageTitleEditTender":"Uredi ponudu",
	"PriceList.Side.searchTitle":"Pretraživanje",
	"Settings.Users.NewUser.identificationNumber":"OIB",
	"Clients.AgreedPrices.Side.Delete.questionYesNo":"Da li zaista želite obrisati dogovorene cijene za {{clientName}}?",
	"Allaround.CompanyType.renter":"Iznajmljivač",
	"PriceList.EditGroup.selectedPicture":"Odabrana slika",
	"Settings.PrintSettings.Header.pageTitle":"Postavke ispisa",
	"Settings.Table.TaxCurrency.defaultVAT":"Porez na dodanu vrijednost (PDV)",
	"Invoice.Header.pageHeaderChange":"Dodaj ili zamijeni zaglavlje ponuda i računa",
	"Invoice.Send.pageTitleOffer":"Slanje ponude",
	"Invoice.Delete.Message.SuccessDelete":"Uspješno ste obrisali stavku {{itemName}}.",
	"Settings.PrintSettings.Table.Pictures.NewFooter.pageTitleNew":"Dodaj podnožje",
	"Settings.SalesPlaces.Edit.Table.city":"Mjesto",
	"Settings.SalesPlaces.Edit.Table.name":"Naziv prodajnog mjesta",
	"Settings.PrintSettings.Table.Invoice.PaymentTypes.title":"Tekstovi u načinima plaćanja",
	"Invoices.Table.ItemMenu.print":"Ispiši",
	"Invoice.Header.pageTitleNewInvoice":"Novi račun",
	"Clients.AgreedPrices.Delete.Group.questionYesNo":"Da li zaista želite obrisati dogovorene uvjete za grupu '{{groupName}}' i sve što je u njoj?",
	"Settings.Table.BasicInformation.name":"Naziv",
	"Settings.PrintSettings.Table.Invoice.NamePersonInCharge.infoTitle":"Ime odgovorne osobe",
	"Settings.MyPictures.Header.newUser":"Dodaj korisnika",
	"Settings.PrintSettings.TextContact.mobile":"Tekst uz mobitel",
	"Settings.Users.Edit.invoicesAllowed":"Korisnik može raditi račune",
	"PriceList.EditProduct.pictureNewProductMessage":"Sliku nije moguće dodati dok se proizvod ne spremi.",
	"Dashboard.newTenderButton":"New Tender",
	"Validation.NoMail":"E-mail ne može ostati prazan.",
	"Validation.NoName":"Ime ne smije ostati prazno.",
	"Settings.SalesPlaces.Edit.Table.SalesPlaceLabelTitle":"Oznake i broj računa",
	"Clients.Edit.namePlaceholder":"Unesi ime klijenta",
	"Settings.SalesPlaces.Edit.Table.streetPlaceholder":"Unesi naziv ulice",
	"Settings.PrintSettings.Table.Invoice.PaymentTypes.textWithWayOfPayment":"Tekst uz način plaćanja",
	"Offers.Table.emptySearchMessage":"Ponuda kakvu ste tražili nije pronađena. \\r\\n\\r\\nMožete ili promijeniti pretragu ili napraviti novu ponudu.",
	"Dashboard.Chart.Month.january":"Siječanj",
	"Invoice.DeleteOffer.Message.errorDelete":"Ponuda nije uspješno obrisana.",
	"Allaround.Message.offerPlural2":"ponuda",
	"Allaround.Message.offerPlural1":"ponude",
	"PriceList.EditGroup.pageTitleNew":"Unesi grupu",
	"Settings.Table.Pricelist.showProductsAsDimensionsInfoTitle":"Dimenzija (širina x visina)",
	"Offers.Table.Header.client":"Klijent",
	"PriceList.EditProduct.Message.successUpdate":"Proizvod uspješno spremljen.",
	"Settings.MyPictures.Messages.errorDelete":"Slika nije uspješno obrisana.",
	"Invoice.Edit.purchuasePrice":"Nabavna cijena",
	"Invoice.Edit.rememberTotalPrice":"Zapamti trenutnu sveukupnu cijenu i pomoću nje računaj popust",
	"Settings.Table.Address.streetNumberPlaceholder":"npr. 10",
	"Invoice.Header.pageFooterChange":"Dodaj ili zamijeni podnožje ponuda i računa",
	"Settings.SalesPlaces.Edit.Table.zipCode":"Poštanski broj",
	"Validation.NoAccountType.Registration":"Odaberite tip subjekta (tvrtke).",
	"Settings.Table.Contact.emailInfo":"Putem ove Email adrese će se slati ponude i računi vašim klijentima.",
	"Invoice.CreateTemplate.templateTitlePlaceholder":"Račun/ponuda za proizvod ABC",
	"Settings.PrintSettings.Table.Invoice.textWithOperator":"Tekst uz ime ili oznaku operatera",
	"Invoice.ChangeAllPrices.profit":"Zarada:",
	"Settings.PrintSettings.Table.Columns.itemDefaultText":"NAZIV",
	"PriceList.DeleteProduct.errorMessage":"Proizvod {{productName}} nije uspješno obrisan.",
	"Settings.SalesPlaces.Edit.Table.namePlaceholder":"Unesi naziv prodajnog mjesta",
	"Settings.SalesPlaces.Edit.Table.SalesPlaceLabelInfo":"Oznaka prodajnog mjesta, naplatnog uređaja i separator se dodaju na broj računa na sljedeći način: ako je oznaka prodajnog mjesta 'HQ', oznaka naplatnog uređaja '1', a separator '-', sve ovo nadodano na broj računa 23 bi bilo 'HQ-1-23'.",
	"Settings.Fiscalisation.Table.CurrentSettings.certificateInfo":"Trenutačni certifikat",
	"Invoice.DeleteTemplate.questionYesNo":"Da li zaista želite obrisati predložak?",
	"Settings.SalesPlaces.Edit.Message.ErrorCreate":"Prodajno mjesto nije uspješno dodano.",
	"Offers.Table.ItemMenu.edit":"Uredi",
	"Invoice.Delete.Message.ErrorDelete":"Stavka nije uspješno obrisana.",
	"Allaround.PaymentType.other.title":"Ostalo",
	"Validation.WrongMail":"Upisana vrijednost nije u obliku e-mail adrese.",
	"Invoice.AddProduct.messageProductExists":"Ovaj proizvod/usluga već postoji u računu i ne možete ga/je ponovno dodati. Ako želite dodati veću količinu tog proizvoda/usluge, promijenite količinu u postojećoj stavci.",
	"Invoice.Send.messagePlaceholder":"Poštovani,\\r\\n\\r\\nu prilogu Vam šaljemo naš račun za obavljene isporuke.\\r\\n\\r\\nLijep pozdrav, \\r\\n\\r\\n Izračunko",
	"Clients.Edit.phone":"Telefon",
	"Invoice.Table.Client.Menu.new":"Novi klijent",
	"Offers.Table.Header.amount":"Iznos",
	"Settings.Users.Edit.PageTitle.infoMessage":"Ukoliko želite da korisnik može sve raditi što i Vi, odaberite opciju \"(Da) Korisnik je administrator\". Ukoliko smatrate kako je bolje da korisnik ne može mijenjati cjenik, postavke, raditi račune te da nema uvid u nabavne cijene i marže, nemojte je odabrati (Ne).",
	"Clients.Edit.email":"Mail",
	"PriceList.EditGroup.pictureEdit":"Uredi",
	"Invoice.ChangeAllPrices.current":"Trenutno stanje",
	"Footer.design":"Dizajn Unity",
	"Allaround.Validation.invalidPriceWithoutDiscount":"Cijena bez popusta treba biti veća od sveukupne cijene (i popust pozitivan).",
	"Settings.PrintSettings.TableTotalName.totalPriceWithVATDefaultText":"SVEUKUPNA CIJENA",
	"Clients.AgreedPrices.Side.Conditions.Message.successSave":"Dogovorene cijene nisu uspješno obrisane.",
	"PriceList.Delete.questionYesNo":"Da li zaista želite obrisati čitavi cjenik? Ova radnja se ne može poništiti!",
	"Settings.Table.Contact.mobile":"Mobitel",
	"Settings.PrintSettings.Table.Invoice.StornoInvoiceNote.infoText":"Ono što ovdje napišete, ispisati će se u napomeni računa koji se automatski napravi prilikom storniranja računa i dodati će se broj storniranog računa. Npr. ako stornirate račun broj 45, a ovdje napišite 'Storno računa broj', u novom računu napraviti će se napomena 'Storno računa broj 45'",
	"Invoice.Table.Header.price":"Cijena",
	"Settings.Table.TaxCurrency.predefinedOfferCurrency":"Valuta ponude",
	"Invoice.Table.Header.total":"Ukupno",
	"Settings.SalesPlaces.Table.Header.numberOfUsers":"Broj prodajnih mjesta",
	"Settings.Fiscalisation.Messages.errorActivationMissingCompanyTaxID":"OIB tvrtke",
	"Settings.PrintSettings.Table.Invoice.PaymentType.bankAccount":"Transakcijski račun",
	"Offers.Table.emptyMessage":"Ponuda je početak vašeg odnosa s klijentom. \\r\\n\\r\\nKad je napravite (botun 'Nova ponuda'), izrazito jednostavno je možete poslati klijentu. Kad vam klijent potvrdi ponudu, iz ponude možete direktno izraditi račun te i njega poslati klijentu.",
	"Settings.PrintSettings.Table.Invoice.invoiceLabel":"Oznaka računa",
	"Invoices.Header.pageTitle":"Računi",
	"Allaround.Message.subscriptionExpiresSoon":"Do isteka pretplatnog perioda ostalo vam je <b>{{numDays}} {{days}}</b>. Za produžetak korištenja naše usluge molimo uplatite željeni paket. Za uvjete <a href=\\\"http://www.izracunko.com/cijene\\\" target=\\\"_blank\\\">kliknite ovdje</a>.",
	"Allaround.Popup.deleteTitle":"Jeste li sigurni?",
	"Invoice.Header.Menu.delete":"Obriši",
	"PriceList.Table.emptyMessage":"Nemate još niti jedan proizvod. \\r\\n\\r\\nJednom unesen proizvod omogućuje vam da ga jednostavno možete odabrati prilikom kreiranja ponude ili računa. Ukoliko imate veći broj proizvoda možete ih organizirati unutar grupa.",
	"Offers.Table.ItemMenu.print":"Ispiši",
	"Invoice.Header.pageTitleNewTemplate":"Novi predložak",
	"Settings.Table.Address.title":"Adresa",
	"Templates.Table.emptyMessage":"Nemate niti jedan predložak. \\r\\n\\r\\Predlošci služe da bi se iz njih stvarale istovrsne ponude ili računi. Predložak se stvara ili iz već postojeće ponude ili računa ili odavde pritiskom na 'Novi predložak'.",
	"Invoice.Print.Fiscalisation.questionYesNo":"Prije ispisa ili slanja, račun se mora fiskalizirati. Nakon uspješne fiskalizacije, račun više nećete moći mijenjati ni obrisati. Da li želite fiskalizirati račun?",
	"Settings.PrintSettings.TextContact.telFax":"Tekst uz tel/fax",
	"Settings.Fiscalisation.Table.UploadCertificate.saveButton":"Dodaj certifikat",
	"Invoice.Edit.itemCodePlaceholder":"Šifra proizvoda/usluge",
	"Settings.Users.NewUser.password":"Lozinka",
	"Offers.Table.Header.date":"Datum",
	"Settings.PrintSettings.Table.Pictures.NewSignature.signatureRemove":"Izbriši potpis",
	"Header.search":"Pretraživanje",
	"Invoice.SendEInvoice.InvoiceProcess.p8":"P8 - Izdavanje računa s referencom na otpremnicu i primku, na temelju narudžbenice",
	"Invoice.SendEInvoice.InvoiceProcess.p9":"P9 - Izdavanje odobrenja ili računa s negativnim iznosima",
	"Invoice.SendEInvoice.InvoiceProcess.p1":"P1 - Izdavanje računa za isporuke robe i usluga prema narudžbenici, na temelju ugovora",
	"Invoice.SendEInvoice.InvoiceProcess.p2":"P2 - Periodično izdavanje računa za isporuke robe i usluga, na temelju ugovora, bez narudžbenice",
	"Invoice.SendEInvoice.InvoiceProcess.p3":"P3 - Izdavanje računa za isporuke prema samostalnoj narudžbi/narudžbenici, bez ugovora",
	"Invoice.SendEInvoice.InvoiceProcess.p4":"P4 - Izdavanje računa za predujam (avans)",
	"Invoice.SendEInvoice.InvoiceProcess.p5":"P5 - Izdavanje računa za plaćanje na licu mjesta",
	"Invoice.SendEInvoice.InvoiceProcess.p6":"P6 - Izdavanje računa za plaćanje prije isporuke, na temelju narudžbenice",
	"Invoice.SendEInvoice.InvoiceProcess.p7":"P7 - Izdavanje računa s referencom na otpremnicu, na temelju narudžbenice",
	"Invoices.Side.buttonSearch":"Traži",
	"Settings.PrintSettings.TableTotalName.calculatedOnInfoTitle":"Tekst između iznosa poreza i ukupnog iznosa stavki po tom porezu",
	"Settings.SalesPlaces.Header.newSalesPlace":"Dodaj prodajno mjesto",
	"Invoice.Send.messageTitleOffer":"Ponuda",
	"Settings.Table.Contact.phone":"Telefon",
	"Invoice.Table.ItemMenu.delete":"Obriši stavku",
	"Settings.Table.Contact.title":"Kontakt informacije",
	"PriceList.Side.importPriceList":"Uvezi cjenik",
	"Allaround.Currency.RSD.shortcode":"din",
	"Invoice.Send.invoiceAttached":"Račun u prilogu",
	"Clients.AgreedPrices.Delete.questionYesNoGroup":"Da li zaista želite obrisati dogovorene uvjete za {{productgroupName}} i sve što je u njoj?",
	"Settings.MyPictures.ItemMenu.edit":"Uredi",
	"Clients.AgreedPrices.Delete.Product.errorMessage":"Uvjeti za proizvod {{productName}} nisu uspješno obrisani.",
	"Settings.Table.Contact.email":"Email adresa",
	"Invoice.Edit.basicPrice":"Osnovna cijena",
	"Allaround.PaymentType.bankAccount.title":"Transakcijski račun",
	"Clients.Edit.country":"Država",
	"Settings.Users.Table.Header.perPage":"po stranici",
	"Offers.Side.year":"Godina (sve)",
	"Clients.Delete.Message.errorDelete":"Klijent nije uspješno obrisan.",
	"Settings.MyPictures.Table.UploadPicture.title":"Dodaj sliku proizvoda",
	"Invoice.Side.sendMail":"Pošalji e-mailom",
	"Invoice.Header.Menu.sendMail":"Pošalji e-mailom",
	"Validation.minLength":"Min Length",
	"Clients.Edit.Message.successUpdate":"Podaci o klijentu su uspješno spremljeni.",
	"PriceList.EditProduct.pictureNonExistingMessage":"Slika ne postoji",
	"PriceList.EditProduct.Message.errorUpdate":"Greška prilikom mijenjanja podataka o proizvodu.",
	"PriceList.EditGroup.picture":"Slika",
	"Allaround.PaymentType.check.title":"Ček",
	"PriceList.Import.Message.infoTitle":"Upute za uvoz cjenika",
	"Settings.Fiscalisation.Table.UploadCertificate.title":"Dodaj certifikat",
	"Clients.AgreedPrices.Side.GeneralTerms.Message.errorSave":"Dogovoreni uvjeti nisu uspješno spremljeni.",
	"Reports.Type.title":"Vrsta izvještaja",
	"Settings.PrintSettings.Table.Columns.measurementUnit":"Naziv stupca jedinice mjere",
	"Reports.until":"Do",
	"Allaround.Language.InvoiceOrOffer.t-it":"Talijanski",
	"Settings.PrintSettings.Table.Pictures.headerDelete":"Da li zaista želite izbrisati zaglavlje?",
	"Invoice.Edit.Message.SuccessCreate":"Stavka uspješno dodana.",
	"Settings.Fiscalisation.Table.UploadCertificate.password":"Lozinka",
	"Clients.AgreedPrices.Table.Header.price":"Cijena",
	"Clients.AgreedPrices.Table.Header.title":"Uvjeti za {{clientName}}",
	"Invoice.Header.FiscalizedInvoice.warningMessage":"Ovaj račun je fiskaliziran i ne može se mijenjati niti brisati.",
	"Settings.PrintSettings.TextContact.telFaxInfoTitle":"Tekst uz tel/fax",
	"Settings.SalesPlaces.Edit.Table.salesPlaceLabel":"Oznaka prodajnog mjesta",
	"Allaround.CompanyType.lawyer":"Odvjetnik",
	"Clients.AgreedPrices.AddProductOrGroup.Message.successAdd":"Uspješno su dodani novi uvjeti.",
	"Settings.Users.NewUser.userCanDoInvoices":"Korisnik može raditi račune",
	"Settings.Table.TaxCurrency.defaultVATInfoTitle":"PDV",
	"Settings.EInvoice.Table.MyEInvoice.infoText":"Moj eRačun je vanjski servis kojim se služimo kako bi automatizirali slanje eRačuna iz našeg sustava.\r\nOve podatke dobijate nakon registracije na servis 'Moj eRačun'. Ukoliko želite to napraviti, javite nam se na info@izracunko.com kako bi vam poslali upute.\r\nUkoliko želite slati eRačune putem nekog drugog servisa, to možete napraviti tako da preuzmete eRačun kada ga naš sustav napravi i prenesete u drugi servis.",
	"Settings.PrintSettings.TextContact.mail":"Tekst uz e-mail",
	"Settings.PrintSettings.TextContact.IBAN":"Tekst uz broj bankovnog računa",
	"Settings.PrintSettings.Table.Invoice.textWithInvoiceDate":"Tekst uz datum računa",
	"Validation.NoLastName":"Prezime ne smije ostati prazno.",
	"ForgotPassword.popupErrorWrongMail":"Korisnik s tom email adresom nije pronađen.",
	"Clients.Edit.companyNumberPlaceholder":"Unesi identifikator klijenta",
	"Settings.MyPictures.Table.PredefinedPictures.buttonBack":"Natrag",
	"Settings.SalesPlaces.Edit.Header.pageTitleNew":"Dodaj prodajno mjesto",
	"Settings.MyPictures.Table.UploadPicture.addPicturePlaceholder":"Slika nije odabrana",
	"Invoices.Side.month":"Mjesec (svi)",
	"Clients.AgreedPrices.Edit.pageTitle":"Uvjeti za proizvod/grupu",
	"Registration.personaldataSurname":"Prezime",
	"Invoice.EditOffer.addPicture":"Dodaj sliku",
	"Settings.SalesPlaces.Side.searchButton":"Traži",
	"Invoice.Table.buttonNew":"Nova stavka",
	"Settings.Users.Edit.Message.userTypes":"Korisnici su osobe ovlaštene za pristup programu Ponude&Računi. Razlikujemo dvije vrste korisnika: administratore i obične korisnike. Obični korisnik ne može mijenjati cjenik, postavke niti raditi račune. Također nema uvid u nabavne cijene i marže, ako ih koristite prilikom formiranja cijene. Administrator može sve raditi.",
	"Settings.SalesPlaces.Edit.Table.cityPlaceholder":"Unesi mjesto",
	"Settings.Users.Table.Header.edit":"Uredi",
	"Settings.Users.Table.Header.mail":"Login E-Mail",
	"Settings.Users.Table.Header.user":"Korisnik",
	"Settings.Users.Edit.userLabel":"Oznaka korisnika",
	"Settings.Users.Edit.Message.errorUpdateMailExists":"Korisnik s tim emailom već postoji.",
	"Header.templates":"Predlošci",
	"Clients.AgreedPrices.Edit.Product.Message.successSave":"Uvjeti za proizvod {{productName}} su uspješno spremljeni.",
	"Settings.Table.PredefinedText.offerNote":"Predefinirana napomena na ponudi",
	"Settings.Fiscalisation.Messages.errorActivationNoCompanyOIB":"Fiskalizaciju nije moguće aktivirati bez unesenog OIB-a tvrtke.",
	"PriceList.Side.searchPlaceholder":"Dio naziva ili šifre",
	"Settings.Users.Edit.namePlaceholder":"Mate",
	"Dashboard.invoiceNumber":"Broj računa",
	"Invoice.Table.OfferNumber.infoText":"Brojevi ponuda automatski se kreiraju počevši od broja 00001. Izgled broja ponude možete izmijeniti po volji, ali samo skroz lijevi dio će se automatski kreirati. Na primjer ako stavite da vaš broj ponude bude 23-01-2023, sljedeći broj ponude će biti 24-01-2023.",
	"Settings.Users.Table.ItemMenu.edit":"Uredi",
	"PriceList.EditProduct.Message.successCreate":"Proizvod uspješno dodan.",
	"Dashboard.viewAllButton":"Vidi sve",
	"Offers.Side.month":"Mjesec (svi)",
	"Popup.no":"Ne",
	"ResetPassword.title":"Izmijeni lozinku",
	"Settings.SalesPlaces.Table.ItemMenu.delete":"Obriši",
	"Dashboard.Chart.TopProductsPerIncome.title":"Najuspješnijih 5 proizvoda",
	"ForgotPassword.resetPasswordButton":"Pošalji upute",
	"Clients.Edit.faxPlaceholder":"Unesi broj faxa",
	"Settings.Table.Pricelist.showPurchaseInfoTitle":"Nabavna cijena i marža",
	"Settings.Users.Edit.surnamePlaceholder":"Matić",
	"ResetPassword.showSuccessMessage":"Uspješno ste izmijenili lozinku",
	"Invoice.Table.Totals.totalWithoutVAT":"Ukupno",
	"Settings.MyPictures.Table.UploadPicture.selectPicture":"Odaberi sliku",
	"Invoice.SaveOffer.Message.successCreate":"Ponuda uspješno dodana.",
	"Dashboard.Chart.Month.may":"Svibanj",
	"Settings.SalesPlaces.Edit.Table.workingTimePlaceholder":"Unesi radno vrijeme",
	"Invoice.Save.Message.errorCreate":"Greška prilikom spremanja računa.",
	"Invoice.Table.Payment.title":"Način plaćanja",
	"Invoice.Edit.totalPricePlaceholder":"Cijena koju plaća klijent (sve je uključeno)",
	"Settings.PrintSettings.Table.Invoice.PaymentType.cash":"Novčanice",
	"Settings.PrintSettings.Table.Invoice.PaymentType.card":"Kartica",
	"Templates.Header.newTemplate":"Novi predložak",
	"Invoices.Side.invoiceNumberPlaceholder":"Broj računa (dio broja)",
	"Settings.Users.Table.Header.delete":"Obriši",
	"Clients.AgreedPrices.AddProductOrGroup.agreedSellPricePlaceholder":"Unesite dogovorenu cijenu",
	"Invoice.DeleteTemplate.Message.successDelete":"Uspješno ste obrisali predložak.",
	"Settings.Table.TaxCurrency.predefinedCurrency":"Valuta unosa",
	"Allaround.Validation.positiveNumber":"{{fieldName}} mora biti pozitivan broj.",
	"Registration.accountdataTitle":"I još samo par podataka za podesiti vaš račun:",
	"Allaround.Validation.maxValue":"{{fieldName}} ne smije biti veći od {{maxValue}}.",
	"Invoice.Print.Fiscalisation.messageSuccess":"Račun uspješno fiskaliziran",
	"ResetPassword.showErrorMessage":"Greška prilikom mijenjanja lozinke",
	"PriceList.Import.Message.warningTitle":"Upozorenje",
	"Settings.PrintSettings.Table.Pictures.footerDelete":"Da li zaista želite izbrisati podnožje?",
	"Clients.Edit.Message.errorCreate":"Greška prilikom spremanja klijenta",
	"Invoice.Send.emailPlaceholder":"primjer@gmail.com",
	"Invoice.Side.actionsTitle":"Radnje",
	"Settings.PrintSettings.TableTotalName.taxDefaultText":"PDV",
	"Allaround.Button.download":"Preuzmi",
	"PriceList.DeleteGroup.errorMessage":"Grupa {{groupName}} nije uspješno obrisana.",
	"Settings.PrintSettings.Table.Pictures.NewSignature.pageTitleEdit":"Zamijeni potpis",
	"Clients.AgreedPrices.Select.title":"Naziv",
	"Invoice.Side.createOffer":"Napravi ponudu",
	"Clients.AgreedPrices.Select.price":"Cijena",
	"Settings.PrintSettings.Table.Columns.totalPrice":"Naziv stupca ukupne cijene stavke",
	"Footer.copyright":"(c) 2010 - 2020 Izračunko",
	"Invoice.Send.email":"E-mail primatelja",
	"PriceList.Import.Table.groupPlaceholder":"Unesi naziv grupe",
	"Settings.SalesPlaces.Table.Header.title":"Popis prodajnih mjesta",
	"Allaround.Currency.CHF.title":"Švicarski franak",
	"Invoice.CreateTemplate.saveClientYesNo":"Spremi klijenta u predložak",
	"Invoice.Edit.itemTaxFree":"Ovaj proizvod/usluga je oslobođen plaćanja poreza.",
	"Allaround.Button.search":"Traži",
	"Allaround.Button.select":"Odaberi",
	"Validation.unknown":"Unknown",
	"Templates.Side.buttonSearch":"Traži",
	"Settings.PrintSettings.Table.Invoice.textWithPaymentDate":"Tekst uz datum dospijeća",
	"Invoice.Table.Totals.taxFree":"Neoporezivo",
	"Registration.showSuccessMessage":"Hvala što ste se odlučili iskušati naš program, nadamo se da će vam se sviditi. Pristupni podaci poslani su na vašu e-mail adresu.<br/>Trenutno imate unesene samo osnovne podatke. Sve ostalo možete precizno postaviti prema vašim potrebama unutar postavki.",
	"Settings.PrintSettings.Table.Pictures.NewFooter.footerTitle":"Podnožje",
	"Settings.Side.eInvoice":"Postavke eRačuna",
	"Settings.SalesPlaces.Edit.Header.pageTitleEdit":"Uredi prodajno mjesto",
	"Settings.Table.Pricelist.predefinedMeasurementsUnits":"Predefinirane mjerne jedinice",
	"Settings.SalesPlaces.Edit.Table.billingDeviceLabelPlaceholder":"Unesi oznaku naplatnog uređaja",
	"Invoice.Table.footerNone":"Dodajte ovdje vaše podnožje",
	"Settings.Side.myPictures":"Moje slike proizvoda",
	"Settings.PrintSettings.Table.Pictures.NewHeader.infoPictureRights":"Slanjem ove slike jamčite da imate pravo na njenu distribuciju.",
	"Allaround.Message.packageThresholdReached":"Možete napraviti još <b>{{numOffersOrInvoicesLeft}} {{offerOrInvoiceSingular}}</b> u ovom mjesecu. Napravili ste <b>{{numOffersOrInvoices}}</b> od <b>{{numOffersOrInvoicesInPackage}}</b> u vašem paketu. Za pogledati uvjete prelaska na veći paket <a href=\\\"http://www.izracunko.com/cijene#nadoplata\\\" target=\\\"_blank\\\">kliknite ovdje</a>.",
	"Invoices.Table.ItemMenu.cancel":"Storniraj",
	"Allaround.Message.successDelete":"Uspješno ste obrisali",
	"PriceList.Delete.pageTitle":"Obriši čitavi cjenik",
	"PriceList.Table.emptyGroupMessage":"Ova grupa nema niti jedan uneseni proizvod niti podgrupu.  \\r\\n\\r\\nMožete se vratiti u nadređenu grupu pritiskom na strelicu 'Nazad' da bi odabrali proizvod u drugoj grupi ili možete unijeti novu stavku.",
	"ForgotPassword.showSuccessMessage":"Uspješno ste poslali zahtjev",
	"PriceList.EditProduct.pictureEdit":"Uredi",
	"Settings.PrintSettings.Table.Invoice.textWithWayOfPayment":"Tekst uz način plaćanja",
	"Settings.EInvoice.Table.userID":"ID Korisnika",
	"Invoice.Edit.pageTitle":"Uredi stavku",
	"Settings.Side.users":"Korisnici tvrtke",
	"Settings.EInvoice.Messages.successSave":"Uspješno spremljeni podaci za eRačun.",
	"Dashboard.offerNumber":"Broj ponude",
	"Settings.Side.title":"Dodatno",
	"Settings.Table.Contact.iban":"Broj bankovnog računa (IBAN)",
	"Settings.Fiscalisation.Table.UploadCertificate.selectCertificate":"Odaberi certifikat",
	"Settings.Users.NewUser.saveAndSendYesNo":"Pošalji korisniku podatke na mail",
	"Invoice.Table.Client.Menu.edit":"Uredi klijenta",
	"Popup.errorTitle":"Greška",
	"Invoice.Table.Header.rowNumber":"#",
	"Settings.EInvoice.Table.Password.title":"Lozinka",
	"Allaround.Currency.InterfaceLanguage.hr-hr":"Hrvatski",
	"Clients.Table.emptyMessage":"<b>Nemate još niti jednog klijenta.</b> \\r\\n\\r\\nKlijenta možete dodati pritiskom na botun 'Novi klijent'.",
	"Invoice.Header.Menu.offerSettings":"Postavke ponude",
	"Clients.Side.searchButton":"Traži",
	"Invoice.Table.DateDue.title":"Datum dospijeća",
	"Login.popupSuccess":"Uspješno ste se ulogirali",
	"Allaround.Message.invoicesNotFiscalized":"Imate račune koji nisu fiskalizirani, a trebali bi biti u roku od 2 dana nakon izdavanja. Ukoliko ih želite fiskalizirati, odaberite njihov ispis.",
	"Invoice.CancelInvoice.questionInfoTitle":"Storniranje računa",
	"Invoice.DeleteInvoice.pageTitle":"Obriši račun",
	"Settings.PrintSettings.TableTotalName.nonTaxableDefaultText":"Neoporezivo",
	"Clients.AgreedPrices.Table.Header.discount":"Popust",
	"Settings.PrintSettings.Table.Contacts.title":"Tekstovi uz kontakt podatke",
	"Invoice.Edit.Message.ErrorUpdate":"Greška prilikom mijenjanja podataka o stavci.",
	"Dashboard.Chart.Month.november":"Studeni",
	"Invoice.Edit.basicPricePlaceholder":"Unesite osnovnu cijenu",
	"Dashboard.searchPlaceholder":"Unesi tekst pretrage",
	"Settings.SalesPlaces.Edit.Table.billingDeviceLabel":"Oznaka naplatnog uređaja",
	"Allaround.PaymentType.creditCard.shortcode":"K",
	"Clients.AgreedPrices.AddProductOrGroup.priceValidation":"{{discount}} ili {{agreedSellPrice}} je nužno unijeti. ",
	"Settings.EInvoice.Table.UserID.title":"ID Korisnika",
	"Settings.Table.BasicInformation.title":"Osnovne informacije",
	"Invoice.Table.Footer.remarkPlaceholder":"Ovdje možete unijeti napomenu koja će biti vidljiva na računu. Npr. o uvjetima plaćanja, predujmu, … ili neku obaveznu napomenu koju vam zakon nameće.",
	"Settings.PrintSettings.TextContact.VATDefaultText":"OIB:",
	"Allaround.Message.day":"dan",
	"Validation.pattern":"Pattern",
	"Invoice.Edit.discount":"Popust {{discountPercentage}}",
	"Settings.Table.Address.city":"Mjesto",
	"Settings.SalesPlaces.Edit.Table.invoicePrefixPlaceholder":"Izgled prefiksa računa za ovo prodajno mjesto",
	"Invoice.Print.Fiscalisation.messageTooLate":"Vrijeme računa starije je od obaveznog roka za slanje na poreznu upravu (2 dana). Ovaj račun više se ne može fiskalizirati. Kontaktirajte vašeg knjigovođu za savjet.",
	"Clients.AgreedPrices.Edit.agreedSellPrice":"Dogovorena prodajna cijena",
	"Clients.Delete.Message.cancelDelete":"Odustali ste od brisanja klijenta.",
	"Allaround.Currency.InvoiceOrTenderLanguage.pl-pl":"Poljski",
	"Invoices.Table.ItemMenu.delete":"Izbriši",
	"Settings.PrintSettings.TableTotalName.totalPrice":"Naziv ukupne cijene",
	"Registration.accountdataName":"Naziv subjekta",
	"Clients.Edit.countryPlaceholder":"Unesi državu",
	"Invoice.Delete.Message.CancelDelete":"Odustali ste od brisanja stavke.",
	"Allaround.Currency.InvoiceOrTenderLanguage.hu-hu":"Madžarski",
	"Allaround.Currency.InvoiceOrTenderLanguage.hr-hr":"Hrvatski",
	"Settings.Table.Address.country":"Država",
	"Registration.title":"Registracija",
	"Allaround.Currency.InvoiceOrTenderLanguage.de-de":"Njemački",
	"Allaround.Currency.InvoiceOrTenderLanguage.it-it":"Talijanski",
	"Invoice.Side.makeTemplate":"Napravi predložak",
	"Allaround.Currency.InvoiceOrTenderLanguage.fr-fr":"Francuski",
	"Allaround.Currency.InvoiceOrTenderLanguage.es-es":"Španjolski",
	"Allaround.Currency.InvoiceOrTenderLanguage.en-gb":"Engleski",
	"Clients.Header.newClient":"Novi klijent",
	"Allaround.Currency.InvoiceOrTenderLanguage.cs-cz":"Češki",
	"Settings.PrintSettings.Table.Columns.title":"Nazivi stupaca u tablici ponude / računa",
	"Settings.PrintSettings.Table.Columns.price":"Naziv stupca cijene stavke",
	"Invoice.DeleteInvoice.Message.CancelDelete":"Odustali ste od brisanja računa.",
	"Invoices.Table.Mobile.created":"Izdao",
	"Allaround.Question.delete":"Da li zaista želite obrisati",
	"Allaround.Message.invoicesGenitiv":"računa",
	"Settings.PrintSettings.Table.Pictures.NewHeader.headerNotSelectedNote":"Dokument nije odabran",
	"Invoices.Header.reports":"Izvještaji",
	"Login.email":"Email",
	"Invoices.Table.Header.perPage":"po stranici",
	"Header.offers":"Ponude",
	"Templates.Table.ItemMenu.delete":"Izbriši",
	"Invoice.Edit.Message.SuccessUpdate":"Stavka spremljena.",
	"Clients.Table.emptySearchMessage":"Klijent kakvog ste tražili nije pronađen. \\r\\n\\r\\nMožete ili promijeniti pretragu ili napraviti novog klijenta.",
	"Invoice.Cancelnvoice.Message.SuccessDelete":"Uspješno ste stornirali račun.",
	"Clients.Table.Header.city":"Mjesto",
	"Clients.Table.Header.name":"Ime",
	"Clients.Table.Header.mail":"Mail",
	"Invoice.Table.Client.noClientPlaceholder":"Odaberi klijenta (upiši dio imena)",
	"Settings.Fiscalisation.Messages.errorActivationCertificateExpired":"Fiskalizaciju nije moguće aktivirati jer je certifikat istekao.",
	"PriceList.Side.deletePriceList":"Obriši cjenik",
	"Dashboard.lastFiveOffers":"Zadnjih 5 ponuda",
	"PriceList.DeleteProduct.successMessage":"Proizvod {{productName}} uspješno obrisan.",
	"Settings.SalesPlaces.Edit.Message.SuccessUpdate":"Podaci o prodajnom mjestu su uspješno spremljeni.",
	"Allaround.Popup.warningTitle":"Upozorenje",
	"Invoice.Header.Menu.print":"Ispiši",
	"Templates.Table.ItemMenu.createInvoice":"Napravi račun",
	"Invoice.Edit.purchasePrice":"Nabavna cijena",
	"Invoice.Edit.itemName":"Naziv stavke",
	"Invoice.Edit.itemCode":"Šifra",
	"Clients.AgreedPrices.Table.emptyMessage":"<b>Nemate još niti jednog klijenta.</b> \\r\\n\\r\\nKlijenta možete dodati pritiskom na botun 'Novi klijent'.",
	"Allaround.Button.accept":"Prihvati",
	"ResetPassword.passwordRepeatPlaceholder":"Ponovi novu lozinku",
	"Invoice.Print.Fiscalisation.printDontFiscalize":"Ispiši bez fiskalizacije",
	"Invoice.EditCurrency.pageTitle":"Postavke valute",
	"Invoice.Send.pageTitleInvoice":"Slanje računa",
	"Allaround.Validation.discountNegative":"Popust mora biti veći od nule.",
	"Settings.Fiscalisation.Messages.errorActivationNoOperatorOIB":"Fiskalizaciju nije moguće aktivirati bez unesenog OIB-a svih operatera.",
	"PriceList.Table.Header.name":"Naziv",
	"Invoice.SendEInvoice.Message.noCompanyData":"Kako bi mogli poslati eRačun, prethodno morate u postavkama unijeti OIB i kontakt email te punu adresu  (ulica, broj, grad, poštanski broj i država).",
	"Offers.Side.clientPlaceholder":"Klijent (upiši dio imena)",
	"Dashboard.pageTitle":"Glavna ploča",
	"Clients.AgreedPrices.Side.Delete.pageTitle":"Brisanje dogovorenih cijena",
	"Allaround.Currency.RSD.title":"Srpski dinar",
	"Invoice.DeleteTemplate.Message.cancelDelete":"Odustali ste od brisanja predloška.",
	"Invoice.Edit.pricePerUnit":"Jedinična cijena:",
	"Settings.PrintSettings.Table.Invoice.title":"Postavke ispisa računa",
	"Dashboard.Chart.Month":"Mjesec",
	"Settings.Table.Address.street":"Ulica",
	"Invoice.EditCurrency.clientCurrency":"Valuta klijenta",
	"Settings.Users.Edit.sellingPlace":"Prodajno mjesto",
	"Settings.PrintSettings.Table.Pictures.NewHeader.messageBadQuality":"Zaglavlje nije dodano. Zbog kvalitete ispisa vaših dokumenata, trebate imati sliku kvalitete od barem 300 dpi (točaka po inču).",
	"Offers.Table.ItemMenu.delete":"Izbriši",
	"Settings.PrintSettings.Table.Totals.title":"Tekstovi unutar stupca totala u tablici ponude / računa",
	"Settings.Users.Edit.Header.pageTitleNew":"Novi korisnik",
	"PriceList.EditProduct.purchasePrice":"Nabavna cijena",
	"Offers.Table.ItemMenu.sendMail":"Pošalji mailom",
	"Invoices.Table.ItemMenu.sendMail":"Pošalji mailom",
	"Invoice.Delete.pageTitle":"Obriši stavku",
	"Templates.Table.Header.perPage":"po stranici",
	"Invoice.Side.productsTitle":"Proizvodi",
	"Settings.Table.BasicInformation.identificationNumberPlaceholder":"Unesi OIB",
	"Settings.Users.Table.Header.admin":"Administrator",
	"Invoice.Table.ItemMenu.edit":"Uredi stavku",
	"Dashboard.Chart.TopOfferedProducts.title":"Najtraženijih 5 proizvoda",
	"Settings.PrintSettings.Table.Invoice.PaymentType.check":"Ček",
	"Clients.Table.ItemMenu.edit":"Uredi",
	"Offers.Table.ItemMenu.cancel":"Storniraj",
	"Settings.Users.Table.Header.title":"Popis korisnika",
	"Settings.PrintSettings.Table.Columns.itemPositionDefaultText":"RB",
	"ResetPassword.passwordPlaceholder":"Nova lozinka",
	"Settings.MyPictures.Header.pageTitle":"Moje slike proizvoda",
	"Dashboard.Chart.TopProducts.title":"Najprodavanijih 5 proizvoda",
	"Invoice.SendEInvoice.deliveryDate":"Datum isporuke",
	"Settings.Users.NewUser.Button.saveAndSend":"Spremi i pošalji podatke na mail",
	"Settings.Users.NewUser.name":"Ime",
	"Invoice.Edit.price":"Cijena",
	"Settings.PrintSettings.Table.Pictures.NewSignature.signatureOverwrite":"Slanje novog potpisa prebrisat će postojeći!",
	"Registration.personaldataEmailPlaceholder":"primjer@gmail.com (email pomoću kojeg ćete ulaziti u program)",
	"Settings.Users.NewUser.userIsAdmin":"Korisnik je administrator",
	"Invoice.Side.changePrices":"Promjena svih cijena",
	"Invoice.SendEInvoice.pageTitleDownload.infoText":"Ukoliko direktno iz našeg sustava želite slati eRačune, potrebno je da ugovorite ovu uslugu s vanjskim servisom 'Moj eRačun' te da njihove pristupne podatke unesete u postavkama eRačuna.\r\nUkoliko ovo želite napraviti, javite nam se na info@izracunko.com kako bi vam poslali upute.\r\nUkoliko želite slati eRačune putem nekog drugog servisa, niže možete preuzeti eRačun i potom ga poslati putem bilo kojeg drugog servisa.",
	"Invoice.Print.Fiscalisation.pageTitle":"Ispis računa (fiskalizacija)",
	"Clients.AgreedPrices.AddProductOrGroup.pagePlaceholder":"Dio naziva proizvoda/grupe",
	"Clients.Edit.Message.errorUpdate":"Greška prilikom mijenjanja podataka o klijentu.",
	"Settings.Fiscalisation.Table.CurrentSettings.active":"Fiskalizacija uključena",
	"Settings.Table.All.buttonShowPrint":"Prikaži u ispisu",
	"PriceList.EditGroup.pictureNonExistingMessage":"Slika ne postoji",
	"Clients.Side.searchPlaceholder":"Dio naziva",
	"Clients.Edit.Message.successCreate":"Klijent uspješno dodan.",
	"Validation.maxLength":"Max Length",
	"Settings.PrintSettings.Table.Pictures.headerNone":"Zaglavlje ne postoji.",
	"Clients.AgreedPrices.Header.pageTitle":"Dogovorene cijene",
	"Allaround.Button.cancel":"Odustani",
	"Invoice.SendEInvoice.Message.noClientData":"Kako bi mogli poslati eRačun, prethodno trebate odabrati klijenta i unijeti njegov email, OIB i punu adresu (ulica, broj, grad, poštanski broj i država).",
	"Clients.AgreedPrices.Edit.priceFromPriceList":"Prodajna cijena iz cjenika je {{priceFromPriceList}}",
	"Settings.PrintSettings.Table.Pictures.NewSignature.signatureAdded":"Potpis dodan.",
	"Invoice.Edit.discountPrice":"Cijena s popustom:",
	"Clients.AgreedPrices.Delete.pageTitle":"Brisanje dogovorene cijene",
	"Settings.SalesPlaces.Header.PageTitle.infoMessage":"Dodavanjem prodajnih mjesta možete imati za svako prodajno mjesto svoje brojeve računa. Kao administrator možete odrediti koji operater radi na kojem prodajnom mjestu.",
	"Settings.PrintSettings.Table.Pictures.NewSignature.signatureTitle":"Potpis",
	"_Test":"Test value. hr-HR - Croatian (Croatia). Croatian translations for Default CompanyType.",
	"Settings.Fiscalisation.Table.UploadCertificate.selectCertificateButton":"Odaberi",
	"Validation.min":"Min",
	"Validation.max":"Max",
	"Validation.url":"Url",
	"Invoice.DeleteTemplate.Message.errorDelete":"Predložak nije uspješno obrisan.",
	"Allaround.selectFilePlaceHolder":"Dokument nije odabran",
	"Settings.Table.Pricelist.showSimilarProductInfoTitle":"Cijene sličnih proizvoda",
	"Settings.Header.pageTitle":"Postavke",
	"Settings.PrintSettings.Table.Invoice.printingMethod":"Način ispisa računa",
	"Invoice.Save.Message.errorUpdate":"Greška prilikom spremanja podataka o računu.",
	"Settings.SalesPlaces.Edit.Table.descriptionPlaceholder":"Ovaj opis ispisati će se ispod prodajnog mjesta na vašim dokumentima",
	"Settings.Users.Edit.mail":"Login e-mail",
	"Settings.Users.Edit.name":"Ime",
	"Clients.AgreedPrices.AddProductOrGroup.discountPlaceholder":"Dogovoreni popust",
	"PriceList.Header.addGroup":"Dodaj grupu",
	"Invoice.Edit.income":"Zarada:",
	"Settings.EInvoice.Table.MyEInvoice.title":"Postavke servisa Moj eRačun",
	"Settings.PrintSettings.Table.Invoice.textInInvoiceFooter":"Tekst u podnožju računa",
	"Settings.Fiscalisation.Messages.errorActivationIntroMessage":"Fiskalizaciju nije moguće aktivirati dok u postavkama ne unesete",
	"Invoice.SendEInvoice.pageTitleSend":"Slanje eRačuna",
	"Settings.Fiscalisation.Messages.successUpload":"Certifikat uspješno dodan.",
	"Settings.Users.emptySearchMessage":"Korisnik kakvog ste tražili nije pronađen. \\r\\n\\r\\nMožete ili promijeniti pretragu ili napraviti novog korisnika.",
	"Clients.AgreedPrices.Side.paymentDelay":"Broj dana odgode plaćanja",
	"Settings.Fiscalisation.Header.pageTitle":"Postavke fiskalizacije",
	"Invoice.Save.Message.successUpdate":"Račun uspješno spremljen.",
	"Invoice.Table.Footer.remarkInvoice":"Napomena na računu",
	"Settings.Fiscalisation.Messages.errorActivationZipCodNotNumeric":"poštanski broj koji ima samo brojke.",
	"Reports.Header.pageTitle":"Izvještaji",
	"Allaround.Currency.BAM.shortcode":"KM",
	"Dashboard.Chart.TopOfferedProducts.chartTitle":"Proizvodi po traganju",
	"Clients.Delete.questionYesNo":"Da li zaista želite obrisati klijenta {{clientName}}?",
	"Dashboard.Chart.TopProducts.chartTitle":"Proizvodi po broju",
	"Allaround.Language.InvoiceOrOffer.sr-Latn":"Srpski",
	"Allaround.Button.delete":"Obriši",
	"Dashboard.newOfferButton":"Nova ponuda",
	"Invoice.SaveOffer.Message.successUpdate":"Ponuda uspješno spremljena.",
	"Settings.Table.Contact.emailInfoTitle":"Email adresa",
	"Settings.Table.Pricelist.title":"Cjenik",
	"Settings.Table.TaxCurrency.predefinedCurrencyInfoTitle":"Valuta unosa",
	"Allaround.Button.modify":"Uredi",
	"Settings.PrintSettings.Table.Pictures.footer":"Podnožje",
	"Settings.Table.Pricelist.showSimilarProductPricesInfo":"Uključivanjem ove opcije cijene proizvoda sličnog naziva biti će vam prikazane prilikom unosa proizvoda. Na taj način lakše možete odrediti koliku cijenu želite postaviti.",
	"Settings.Users.Delete.SuccessDelete":"Uspješno ste obrisali korisnika {{userName}}.",
	"Invoice.Header.CancelledInvoice.warningMessage":"Ovaj račun je storniran i ne može se mijenjati niti brisati.",
	"Invoice.CreateTemplate.pageTitle":"Unesi naziv predloška",
	"Reports.Type.productReport":"Izvještaj po proizvodu",
	"Registration.promoText":"Isprobajte besplatno 30 dana",
	"Settings.PrintSettings.Table.Invoice.textWithInvoiceNumber":"Tekst uz broj računa",
	"Invoice.EditCurrency.templateCurrency":"Valuta predloška",
	"Validation.NoAccountName.Registration":"Naziv subjekta ne smije ostati prazan.",
	"Settings.Users.NewUser.userIsAdminInfo":"Ukoliko želite da korisnik može sve raditi što i Vi, odaberite opciju \"(Da) Korisnik je administrator\". Ukoliko smatrate kako je bolje da korisnik ne može mijenjati cjenik, postavke, raditi račune te da nema uvid u nabavne cijene i marže, nemojte je odabrati (Ne).",
	"Settings.PrintSettings.Table.Invoice.NamePersonInCharge.infoText":"Ono što ovdje napišete, uvijek će se ispisivati u potpisu ponuda i računa. A ukoliko ovdje ništa ne napišete ispisivati će se ime i prezime trenutnog operatera.",
	"Settings.EInvoice.Table.ObligatoryInvoiceData.title":"Obavezni podaci na računu (temeljni kapital, član uprave, …)",
	"Settings.Users.NewUser.personTitle":"Titula",
	"Clients.Edit.city":"Mjesto",
	"Clients.Edit.name":"Ime",
	"PriceList.Import.Table.fileTitle":"Datoteka",
	"PriceList.Import.Message.infoText":"Odaberite XML datoteku iz koje ćete uvesti cjenik. Ovdje imate primjer <a class=\\\"link\\\" href=\\\"#\\\">XML datoteke</a>, a ovdje imate <a class=\\\"link\\\" href=\\\"#\\\"> Excel 2007 datoteku</a> iz koje smo dobili ovaj primjer odabirom 'Spremi kao -> XML Data'. Nije nužno da unosite sve elemente: kada unosite proizvod koji nije u grupi, dovoljno je da unesete njegov naziv, a kada unosite praznu grupu, dovoljno je da unesete njen naziv unutar GrupaUKojojSeNalaziProizvod.",
	"Settings.PrintSettings.TextContact.fax":"Tekst uz fax",
	"Settings.PrintSettings.TextContact.web":"Tekst uz web adresu",
	"Settings.PrintSettings.TextContact.VAT":"Tekst uz porezni broj",
	"Allaround.Message.offersGenitiv":"ponuda",
	"Invoices.Side.searchTitle":"Pretraživanje",
	"Popup.successTitle":"Uspjeh",
	"Settings.MyPictures.Table.MyPictures.buttonDelete":"Izbriši",
	"Clients.AgreedPrices.Delete.Group.errorMessage":"Dogovoreni uvjeti za grupu {{groupName}} nisu uspješno obrisani.",
	"Invoice.Table.buttonChangeAllPrices":"Promjena svih cijena",
	"Settings.Table.PredefinedText.invoiceNote":"Predefinirana napomena na računu",
	"Clients.Edit.fax":"Fax",
	"Invoice.Header.Menu.makeTemplate":"Napravi predložak",
	"Clients.AgreedPrices.Delete.questionYesNo":"Da li zaista želite obrisati dogovorenu cijenu za {{productgroupName}}?",
	"Settings.EInvoice.Table.ObligatoryInvoiceData.infoText":"Iako sve obavezne podatke najvjerojatnije imate na računu, trebaju nam i na jednom mjestu za slanje eRačuna. Koji su to, možete saznati na našem <u><a href=\\\"http://www.izracunko.com/blog/entry/sto-treba-biti-na-racunu-kojeg-izdaje-hrvatski-poduzetnik\\\">blogu (kliknite)</a></u> u odjeljku Obavezni elementi na računima za d.o.o. ili Obrt.",
	"Login.forgotPassword":"Zaboravili ste lozinku?",
	"Settings.EInvoice.Table.obligatoryInvoiceData":"Obavezni podaci na računu (temeljni kapital, član uprave, …)",
	"Clients.Edit.printRemarkPlaceholder":"Trenutno se napomena neće ispisati uz klijenta. Ukoliko ovdje nešto unesete, to će se ispisati na ponudi ili računu.",
	"Allaround.Currency.USD.title":"Američki dolar",
	"Clients.Edit.address":"Ulica i broj",
	"PriceList.EditProduct.productCode":"Šifra proizvoda",
	"Settings.SalesPlaces.Edit.Table.county":"Općina",
	"Footer.help":"Pomoć",
	"Settings.PrintSettings.Table.Invoice.Unfiscalized.infoText":"Ovaj račun nije fiskaliziran.",
	"Invoices.Table.emptySearchMessage":"Račun kakvog ste tražili nije pronađen. \\r\\n\\r\\nMožete ili promijeniti pretragu ili napraviti novi račun.",
	"PriceList.Table.Header.title":"Popis proizvoda",
	"PriceList.Table.Header.price":"Cijena",
	"Allaround.Language.Interface.hr-hr":"Hrvatski",
	"Settings.MyPictures.Table.PredefinedPictures.title":"Izračunko set slika",
	"Allaround.Language.Interface.en-en":"English",
	"Dashboard.title":"Dashboard",
	"Invoice.Table.buttonNewFromPricelist":"Dodaj stavku iz cjenika",
	"Invoice.Send.offerAttached":"Ponuda u prilogu",
	"Allaround.Validation.password":"Lozinka mora imati barem 6 znakova, od čega jedno malo, jedno veliko slovo i jedan broj.",
	"Invoices.Header.newInvoice":"Novi račun",
	"Settings.SalesPlaces.Edit.Message.SuccessCreate":"Prodajno mjesto je uspješno dodano.",
	"Settings.PrintSettings.Table.Pictures.NewSignature.pageTitleNew":"Dodaj potpis",
	"Header.admin":"Administracija",
	"Breadcrumb.clients":"Klijenti",
	"Invoice.Table.TemplateName.noTitlePlaceholder":"Predložak za proizvod ABC",
	"Clients.AgreedPrices.paymentDelay":"Broj dana odgode plaćanja",
	"Invoice.Header.Menu.cancelInvoice":"Storniraj račun",
	"Settings.Table.Pricelist.showProductsAsDimensions":"Proizvode prikaži kao dimenziju (širina x visina)",
	"Clients.Table.ItemMenu.agreedPrices":"Dogovorene cijene",
	"Settings.Users.Edit.passwordPlaceholder":"NitkoDrugiNeZna1#",
	"Clients.AgreedPrices.Edit.product":"Proizvod",
	"Settings.PrintSettings.Table.Pictures.header":"Zaglavlje",
	"Settings.Users.NewUser.pageTitle":"Dodaj korisnika",
	"Settings.MyPictures.Messages.errorUploadType":"Nedozvoljeni tip slike. Dozvoljeni tipovi su: png, jpg i gif.",
	"Settings.MyPictures.Messages.errorUploadSize":"Slika je prevelika. Maksimalna dopuštena veličina slike je 100 KB.",
	"Settings.Table.TaxCurrency.useTotalPriceInfo":"Sveukupna cijena je krajnja cijena za kupca. Program će sam obračunati pripadajući PDV i popust.",
	"Reports.Type.salesBook":"Knjiga prometa",
	"ForgotPassword.pageTitle":"Zaboravili ste lozinku?",
	"Settings.Table.TaxCurrency.companyInVAT":"Izdavatelj računa je u sustavu PDV-a",
	"Settings.Users.Edit.buttonSaveAndSend":"Spremi i pošalji podatke na e-mail",
	"Settings.Table.BasicInformation.nameInfoTitle":"Naziv",
	"Settings.SalesPlaces.Delete.questionYesNo":"Da li zaista želite izbrisati prodajno mjesto {{salesPlaceNameAddress}}? ",
	"Clients.AgreedPrices.Table.Header.perPage":"po stranici",
	"Popup.close":"Zatvori",
	"Clients.Table.Header.perPage":"po stranici",
	"Allaround.Validation.minLength":"{{fieldName}} mora imati barem {{minLength}} znakova.",
	"Settings.SalesPlaces.Edit.Table.salesPlaceHaveAddressYesNo":"Prodajno mjesto ima adresu",
	"Invoice.SendEInvoice.invoiceProcess":"Oznaka poslovnog procesa",
	"Clients.AgreedPrices.pageTitle":"Dogovorene cijene",
	"Settings.Table.PredefinedText.mailInvoice":"Predefinirani mail uz račun",
	"PriceList.EditProduct.productPlaceholder":"Unesi naziv proizvoda",
	"Invoice.Edit.Message.ErrorCreate":"Greška prilikom spremanja stavke",
	"Settings.Users.Table.Header.numberOfUsers":"Broj korisnika",
	"Reports.from":"Od",
	"Settings.PrintSettings.Table.Pictures.signatureNone":"Potpis ne postoji.",
	"Invoices.Side.clientPlaceholder":"Klijent (upiši dio imena)",
	"PriceList.EditProduct.priceDifference":"Razlika u cijeni",
	"Settings.PrintSettings.Table.Pictures.footerNone":"Podnožje ne postoji.",
	"Settings.Fiscalisation.Messages.errorActivationMissingPersonalIDs":"OIB-ove svih operatera (korisnika programa)",
	"Allaround.Currency.HRK.title":"Hrvatska kuna",
	"Settings.Table.Address.postNumberPlaceholder":"npr. 21000",
	"Settings.MyPictures.Table.Header.admin":"Administrator",
	"Invoice.Send.messageTitle":"Račun",
	"Invoice.Table.Footer.remarkOffer":"Napomena na ponudi",
	"ForgotPassword.popupSuccesResetSent":"Zahtjev za promjenom lozinke poslan je na email adresu {{email}}. Lozinku možete promijeniti sljedećih sat vremena.",
	"Settings.SalesPlaces.Edit.warningMessage":"PAŽNJA: Kada imate aktivnu fiskalizaciju, svaka promjena prodajnog mjesta šalje se na poreznu upravu. Ukoliko promijenite oznaku prodajnog mjesta, ta ista oznaka mora vam biti i na računima.",
	"Header.priceList":"Cjenik",
	"Settings.MyPictures.Table.Header.title":"Popis korisnika",
	"Settings.Fiscalisation.Table.CurrentSettings.title":"Fiskalizacija",
	"Settings.EInvoice.Messages.warningMissingCompanyData":"Kako bi mogli poslati eRačun, morate unijeti OIB, kontakt email te kompletnu adresu (ulica, broj, grad, poštanski broj i država).",
	"Settings.Users.Edit.title":"Titula",
	"Settings.Users.Edit.admin":"Korisnik je administrator",
	"Allaround.PaymentType.other.shortcode":"O",
	"Settings.Fiscalisation.Table.UploadCertificate.passwordDefaultText":"Unesi lozinku",
	"Settings.PrintSettings.Table.Invoice.textWithOfferNumberDefaultText":"Ponuda broj",
	"Settings.PrintSettings.Table.Columns.itemQuantity":"Naziv stupca količine stavke",
	"Settings.Fiscalisation.Messages.errorActivationOIBsDontMatch":"Fiskalizaciju nije moguće aktivirati jer OIB-a tvrtke ne odgovara OIB-u u certifikatu.",
	"Invoice.Save.Message.successCreate":"Račun uspješno dodan.",
	"Invoice.Edit.buttonCancel":"Odustani",
	"Settings.PrintSettings.Table.Invoice.stornoInvoiceNoteDefaultText":"Storno računa broj",
	"Invoice.Table.Totals.total":"Sveukupno",
	"Invoice.EditCurrency.invoiceCurrency":"Valuta računa",
	"Allaround.Validation.mailExists":"Ta e-mail adrese već postoji.",
	"Allaround.Currency.BAM.title":"Konvertibilna marka BiH",
	"Invoice.Send.messagePlaceholderOffer":"Poštovani, \\r\\n\\r\\nu prilogu Vam šaljemo našu ponudu. \\r\\n\\r\\nLijep pozdrav, \\r\\n\\r\\n Izračunko",
	"Invoice.SaveTemplate.Message.successCreate":"Predložak uspješno dodan.",
	"Invoice.Table.Item.notePlaceholder":"Ovdje možete unijeti napomenu uz proizvod/uslugu.",
	"Invoice.Header.Menu.changePrices":"Promjena svih cijena",
	"Allaround.CompanyType.other":"Ostalo",
	"Clients.Table.ItemMenu.delete":"Obriši",
	"Allaround.CompanyType.basic":"Osnovno",
	"Settings.EInvoice.Table.ObligatoryInvoiceData.infoTitle":"Obavezni podaci na računu ",
	"Invoice.Table.Footer.remark":"Napomena",
	"Settings.EInvoice.Table.password":"Lozinka",
	"Settings.Table.Address.postNumber":"Poštanski broj",
	"Invoice.DeleteOffer.Message.successDelete":"Uspješno ste obrisali ponudu.",
	"Registration.accountdataPasswordPlaceholder":"Lozinka pomoću koje ćete ulaziti u program",
	"Invoice.Edit.totalWithVAT":"Sveukupno:",
	"Settings.PrintSettings.TableTotalName.calculatedOn":"obračunato na",
	"Invoice.Side.createInvoice":"Napravi račun",
	"Dashboard.Chart.Month.april":"Travanj",
	"Dashboard.lastFiveTenders":"Last Five Tenders",
	"Dashboard.Chart.Month.march":"Ožujak",
	"PriceList.Side.exportPriceList":"Izvezi cjenik",
	"Invoice.AddProduct.messageProductExistsOffer":"Ovaj proizvod/usluga već postoji u ponudi i ne možete ga/je ponovno dodati. Ako želite dodati veću količinu tog proizvoda/usluge, promijenite količinu u postojećoj stavci.",
	"Validation.required":"Required",
	"Invoice.CreateTemplate.Message.successCreate":"Predložak uspješno stvoren.",
	"Invoice.Header.pageTitleEditOffer":"Uredi ponudu",
	"Clients.Edit.clientRemarkPlaceholder":"Unesi internu napomenu o klijentu",
	"Popup.infoTitle":"Obavijest",
	"Settings.Users.Edit.Message.SuccessUpdate":"Podaci o korisniku su uspješno spremljeni.",
	"PriceList.Table.Header.perPage":"po stranici",
	"Settings.PrintSettings.Table.Invoice.showPaymentDate":"Prikaži datum dospijeća i kada nema odgode plaćanja",
	"Settings.Table.Pricelist.showGroupsInPrint":"U ispisu svojih proizvoda želim prikazati grupe iz cjenika",
	"Settings.MyPictures.Table.UploadPicture.addPictureButton":"Dodaj",
	"Login.password":"Lozinka",
	"Invoices.Side.year":"Godina (sve)",
	"Invoices.Table.emptyMessage":"Niste napravili još niti jedan račun. \\r\\n\\r\\nRačune najčešće izdajete po obavljenom poslu ili prije odrađenog posla (tada je to plaćanje unaprijed).",
	"Settings.PrintSettings.TextContact.telFaxDefaultText":"Tel/Fax:",
	"Header.invoices":"Računi",
	"Settings.Save.Message.successUpdate":"Postavke uspješno spremljene.",
	"Clients.Header.pageTitle":"Klijenti",
	"Reports.noInvoicesMessage":"Ne postoji niti jedan račun u odabranom periodu",
	"Settings.Table.Address.cityPlaceholder":"npr. Split",
	"Invoice.Table.Header.discount":"Popust",
	"Clients.AgreedPrices.AddProductOrGroup.agreedSellPrice":"Dogovorena prodajna cijena",
	"Invoice.Table.Footer.remarkText":"Plaćanje predujma 30% prije početka izvođenja radova, ostatak vrijednosti plaća se nakon izvođenja radova. Rok isporuke 10 dana od uplate predujma. Ponuda vrijedi 15 dana.",
	"PriceList.Table.ItemMenu.edit":"Uredi",
	"Allaround.Validation.maxFileSize":"Datoteka je prevelika. Maksimalna veličina je {{maxFileSize}} kb.",
	"Settings.PrintSettings.Table.Invoice.textWithPersonInChargeDefaultText":"Odgovorna osoba",
	"PriceList.EditProduct.pageTitleEdit":"Uredi proizvod",
	"Validation.NoPassword":"Lozinka ne može ostati prazna.",
	"Dashboard.Chart.Month.october":"Listopad",
	"PriceList.EditGroup.pictureNewGroupMessage":"Sliku nije moguće dodati dok se grupa ne spremi.",
	"Invoice.Table.Date.title":"Datum i vrijeme",
	"Invoice.Edit.measurementUnit":"Jedinica mjere",
	"Header.clients":"Klijenti",
	"Invoice.Side.print":"Ispiši",
	"Settings.Side.fiscalisation":"Postavke fiskalizacije",
	"ForgotPassword.showErrorMessage":"Greška prilikom slanja emaila. Neispravan email.",
	"PriceList.EditGroup.namePlaceholder":"Unesi grupu",
	"Allaround.Validation.mail":"Upisana vrijednost nije u obliku e-mail adrese.",
	"Allaround.Validation.date":"Uneseni datum nije ispravan. Datum mora biti u obliku 31.01.2020. 14:59",
	"Invoice.Table.OfferNumber.infoTitle":"Brojevi ponuda",
	"Clients.AgreedPrices.buttonSave":"Spremi",
	"Clients.Edit.pageTitleEdit":"Uredi klijenta",
	"Invoice.Table.Currency.title":"Valuta",
	"Allaround.DropDown.Select":"Odaberi",
	"PriceList.Import.Table.fileNoneMessage":"Dokument nije odabran",
	"Dashboard.lastFiveInvoices":"Zadnjih 5 računa",
	"Settings.EInvoice.Table.ObligatoryData.title":"Obavezni podaci",
	"Settings.SalesPlaces.Edit.Table.street":"Ulica",
	"Invoice.DeleteInvoice.questionYesNo":"Da li zaista želite obrisati račun?",
	"Invoice.SaveTemplate.Message.errorUpdate":"Greška prilikom spremanja podataka o predlošku.",
	"Settings.PrintSettings.Table.Columns.totalPriceDefaultText":"IZNOS",
	"Registration.accountdataTypeSelector":"Tip subjekta",
	"Settings.Users.Header.pageTitle":"Korisnici",
	"Settings.Users.Edit.identificationNumberPlaceholder":"12345678901",
	"Settings.Table.TaxCurrency.useTotalPrice":"Želim unositi Sveukupnu cijenu (uključuje PDV i popust)",
	"Invoice.Edit.buttonSave":"Prihvati",
	"Invoice.Edit.itemNamePlaceholder":"Unesite naziv",
	"Settings.Table.TaxCurrency.useMoreCurrencies":"Želim izdavati dokumente u više valuta",
	"Clients.AgreedPrices.generalDiscount":"Opći popust",
	"Registration.personaldataSurnamePlaceholder":"Vaše prezime",
	"Invoice.Edit.remark":"Napomena",
	"PriceList.Header.pageTitle":"Cjenik",
	"Allaround.Validation.required":"{{fieldName}} je obavezan podatak.",
	"Settings.Table.Pricelist.showGroupsInPrintInfoTitle":"Kreiranje naziva proizvoda",
	"Settings.PrintSettings.Table.Invoice.StornoInvoiceNote.infoTitle":"Napomena uz storno računa",
	"Invoice.Table.InvoiceNumber.infoText":"Brojevi računa automatski se kreiraju počevši od broja 00001. Izgled broja računa možete izmijeniti po volji, ali samo skroz lijevi dio će se automatski kreirati. Na primjer ako stavite da vaš broj računa bude 23-01-2023, sljedeći broj računa će biti 24-01-2023.",
	"Invoice.Send.additionalRecipientsInfoTitle":"Dodatni primatelji",
	"Settings.Fiscalisation.Messages.errorUpload":"Certifikat nije uspješno dodan.",
	"Invoice.ChangeAllPrices.new":"Novo stanje",
	"Invoices.Table.ItemMenu.edit":"Uredi",
	"PriceList.DeleteProduct.questionYesNo":"Da li zaista želite obrisati proizvod {{productName}}?",
	"Invoices.Table.Header.title":"Popis računa",
	"Settings.SalesPlaces.Edit.Table.invoiceSufixPlaceholder":"Izgled sufiksa (nastavka) računa za ovo prodajno mjesto",
	"Clients.Edit.companyNumber":"Porezni broj (OIB)",
	"Settings.MyPictures.Table.UploadPicture.saveButton":"Dodaj sliku",
	"Settings.SalesPlaces.Edit.Table.salesPlaceLabelPlaceholder":"Unesi oznaku prodajnog mjesta",
	"Clients.AgreedPrices.buttonSelectFromPricelist":"Odaberi iz cjenika",
	"Settings.Table.TaxCurrency.companyInVATInfoTitle":"Porez na dodanu vrijednost (PDV)",
	"Reports.Type.salesReport":"Izvještaj o prometu",
	"Offers.Table.Header.title":"Popis ponuda",
	"PriceList.EditProduct.picture":"Slika",
	"Settings.SalesPlaces.Edit.Table.streetNumberPlaceholder":"Unesi broj ulice",
	"Settings.PrintSettings.Table.Columns.measurementUnitDefaultText":"JM",
	"Breadcrumb.dashboard":"Glavna ploča",
	"Allaround.Message.dayPlural":"dana",
	"Invoice.Side.productsDescription":"Lista najfrekventnijih proizvoda. Možete ih dodati klikom na +.",
	"Templates.Table.Header.templateName":"Naziv",
	"Settings.PrintSettings.Message.errorUpdate":"Greška prilikom spremanja postavki ispisa.",
	"Settings.PrintSettings.Table.Pictures.NewSignature.signatureRemoved":"Potpis izbrisan.",
	"Clients.AgreedPrices.Side.Delete.Message.errorDelete":"Dogovorene cijene nisu uspješno obrisane.",
	"Registration.personaldataName":"Ime",
	"Templates.Table.Header.creator":"Napravio",
	"Invoice.Header.Menu.createOffer":"Napravi ponudu",
	"Invoice.EditCurrency.offerCurrency":"Valuta ponude",
	"Settings.SalesPlaces.Edit.Header.pageTitle":"Uredi prodajno mjesto",
	"Settings.Table.Pricelist.showProductsAsDimensionsInfo":"Ukoliko je većina vaših proizvoda određena širinom i visinom tada uključite ovu opciju (npr. prozor). Za ostale proizvode možete je isključiti prilikom unosa u cjenik.",
	"Invoice.Edit.vat":"PDV {{vatPercentage}}",
	"Settings.PrintSettings.Table.Invoice.textWithPaymentDateDefaultText":"Datum dospijeća",
	"ResetPassword.buttonText":"Izmijeni lozinku",
	"Allaround.Message.invoiceAkuzativ":"račun",
	"Invoice.Delete.questionYesNo":"Da li zaista želite obrisati stavku {{itemName}}?",
	"Clients.Edit.clientRemark":"Napomena o klijentu (interna)",
	"Settings.PrintSettings.Table.Invoice.textWithOperatorDefaultText":"Operater:",
	"Settings.MyPictures.Table.UploadPicture.selectPicturePlaceholder":"Slika nije odabrana",
	"Settings.Table.BasicInformation.identificationNumber":"Porezni broj (OIB)",
	"Invoice.Send.additionalRecipients":"E-mail dodatnih primatelja (cc)",
	"Allaround.Button.print":"Ispiši",
	"PriceList.EditProduct.productCodePlaceholder":"Unesite šifru proizvoda",
	"Settings.PrintSettings.TextContact.mobileDefaultText":"Mob:",
	"PriceList.EditGroup.pageTitleEdit":"Uredi grupu",
	"Clients.Edit.printRemark":"Napomena za ispis",
	"Clients.Edit.additionalInformation":"Dodatne informacije",
	"Clients.AgreedPrices.Delete.Product.successMessage":"Uvjeti za proizvod {{productName}} uspješno obrisani.",
	"Breadcrumb.settings":"Postavke",
	"Settings.PrintSettings.TableTotalName.totalPriceDefaultText":"UKUPNA CIJENA",
	"PriceList.EditGroup.Message.errorUpdate":"Greška prilikom mijenjanja podataka o grupi.",
	"Clients.AgreedPrices.Edit.Group.Message.successSave":"Dogovoreni uvjeti za grupu {{groupName}} su uspješno spremljeni.",
	"Allaround.Validation.exactLength":"{{fieldName}} mora imati {{exactLength}} znakova.",
	"Registration.personaldataTitle":"Za početak nam treba malo vaših osobnih podataka:",
	"Settings.SalesPlaces.Side.searchPlaceholder":"Dio naziva",
	"Settings.PrintSettings.Table.Pictures.NewHeader.bigPictureNote":"Datoteka je prevelika. Maksimalna veličina je {{maxFileSize}} kb.",
	"PriceList.EditProduct.pageTitleNew":"Unesi proizvod",
	"PriceList.Import.Header.title":"Odaberi XML datoteku",
	"Settings.Users.Side.searchTitle":"Pretraživanje",
	"PriceList.EditProduct.measurementUnit":"Jedinica mjere",
	"Invoice.SendEInvoice.pageTitleDownload.infoTitle":"Kako poslati eRačun?",
	"Invoice.SendEInvoice.InvoiceProcess.p11":"P11 - Izdavanje djelomičnog i završnog računa",
	"Invoice.SendEInvoice.InvoiceProcess.p10":"P10 - Izdavanje ispravka računa (korektivno fakturiranje), prema izvornom računu",
	"Invoice.SendEInvoice.InvoiceProcess.p12":"P12 - Samoizdavanje računa",
	"Validation.email":"Email",
	"Registration.personaldataEmail":"Email",
	"Clients.AgreedPrices.Side.generalDiscount":"Opći popust",
	"Invoice.DeleteOffer.questionYesNo":"Da li zaista želite obrisati ponudu?",
	"Dashboard.Chart.Month.february":"Veljača",
	"Settings.Fiscalisation.Messages.successActivation":"Fiskalizacija uspješno aktivirana.",
	"Settings.Side.salesPlaces":"Prodajna mjesta",
	"Settings.Table.Pricelist.showGroupsInPrintInfo":"Naziv proizvoda na ispisu se slaže od naziva grupa, podgrupa i naziva proizvoda. Npr. grupa KEMIJSKA OLOVKA + podgrupa TOZ + proizvod PLAVA daje ukupni naziv stavke KEMIJSKA OLOVKA TOZ PLAVA.",
	"Settings.MyPictures.Table.UploadPicture.maxPictureSize":"Maksimalna dopuštena veličina slike je 100 KB.",
	"Settings.PrintSettings.Table.Pictures.NewSignature.signatureViolation":"Potpis nije dodan. Maksimalna dozvoljena širina je 7cm, a visina 4cm.",
	"PriceList.Table.emptySearchMessage":"Traženi pojam nije pronađen ni među grupama ni među proizvodima. \\r\\n\\r\\nJeste li sigurni da imate takav proizvod ili grupu?",
	"Templates.Table.ItemMenu.createOffer":"Napravi ponudu",
	"Settings.Users.Edit.personTitle":"Titula",
	"Clients.Table.Header.numberOfClients":"Broj klijenata",
	"Invoice.DeleteOffer.Message.cancelDelete":"Odustali ste od brisanja ponude.",
	"Clients.Side.searchTitle":"Pretraživanje",
	"Settings.PrintSettings.Table.Columns.discountDefaultText":"POPUST",
	"Settings.PrintSettings.Table.Pictures.title":"Zaglavlje, podnožje i potpis",
	"Clients.AgreedPrices.AddProductOrGroup.discount":"Popust",
	"Settings.SalesPlaces.Table.Header.numberOfSalesPlaces":"Broj prodajnih mjesta",
	"Dashboard.Chart.Month.august":"Kolovoz",
	"Clients.AgreedPrices.Table.ItemMenu.delete":"Obriši",
	"Allaround.Currency.EUR.title":"Euro",
	"Allaround.Validation.maxLength":"{{fieldName}} ne smije sadržavati više od {{maxLength}} znakova.",
	"Settings.MyPictures.Messages.errorUploadUnknown":"Slika nije uspješno dodana.",
	"Allaround.Currency.GBP.title":"Britanska funta",
	"Offers.Table.Header.perPage":"po stranici",
	"Clients.Delete.pageTitle":"Obriši klijenta",
	"Invoice.Table.DocumentLanguage.title":"Jezik dokumenta",
	"Invoice.CreateTemplate.templateTitle":"Naziv",
	"Allaround.Language.InvoiceOrOffer.hu-hu":"Madžarski",
	"Allaround.Language.InvoiceOrOffer.hr-hr":"Hrvatski",
	"Allaround.Language.InvoiceOrOffer.it-it":"Talijanski",
	"Allaround.Language.InvoiceOrOffer.cs-cz":"Češki",
	"Allaround.Language.InvoiceOrOffer.de-de":"Njemački",
	"Allaround.Language.InvoiceOrOffer.en-gb":"Engleski",
	"Allaround.Language.InvoiceOrOffer.es-es":"Španjolski",
	"Allaround.Language.InvoiceOrOffer.fr-fr":"Francuski",
	"PriceList.EditGroup.Message.successCreate":"Grupa uspješno dodana.",
	"Invoice.DeleteTemplate.pageTitle":"Obriši predložak",
	"Settings.PrintSettings.Table.Pictures.NewHeader.selectButton":"Odaberi",
	"Settings.PrintSettings.TableTotalName.calculatedOnInfo":"Ovaj tekst se pojavljuje uz iznos poreza koji je 'obračunat na' ukupni iznos stavki po tom porezu.",
	"Allaround.Validation.requiredSelect":"Ovaj podatak ({{fieldName}}) je nužno odabrati.",
	"Invoice.Header.pageTitleNewTender":"Nova ponuda",
	"Allaround.Language.InvoiceOrOffer.pl-pl":"Poljski",
	"Settings.Save.Message.errorUpdate":"Greška prilikom spremanja postavki.",
	"Settings.PrintSettings.Table.Pictures.NewFooter.messageBadQuality":"Podnožje nije dodano.<br/>Zbog kvalitete ispisa vaših dokumenata, trebate imati sliku kvalitete od barem 300 dpi (točaka po inču).",
	"Invoice.CreateTemplate.Message.errorCreate":"Predložak nije uspješno stvoren.",
	"Settings.Fiscalisation.Messages.errorUploadWrongPassword":"Niste unijeli ispravnu lozinku certifikata",
	"Settings.EInvoice.Header.pageTitle":"Postavke eRačuna",
	"Settings.PrintSettings.Table.Invoice.namePersonInCharge":"Ime odgovorne osobe",
	"Clients.AgreedPrices.Header.selectFromPricelist":"Odaberi iz cjenika",
	"Invoice.ChangeAllPrices.total":"Ukupno:",
	"Invoice.Table.InvoiceNumber.infoTitle":"Brojevi računa",
	"Clients.AgreedPrices.Edit.group":"Grupa",
	"Clients.AgreedPrices.Delete.Group.successMessage":"Dogovoreni uvjeti za grupu {{groupName}} i sve u njoj su uspješno obrisani.",
	"Settings.Users.NewUser.lastName":"Prezime",
	"Clients.Edit.cellphone":"Mobitel",
	"Clients.Edit.emailPlaceholder":"Unesi mail adresu",
	"Clients.AgreedPrices.Side.GeneralTerms.Message.successSave":"Dogovoreni uvjeti su uspješno spremljeni.",
	"Clients.Edit.cityPlaceholder":"Unesi mjesto",
	"Invoice.DeleteOffer.pageTitle":"Obriši ponudu",
	"Invoice.Send.additionalRecipientsPlaceholder":"primjer@gmail.com, primjer2@gmail.com",
	"Validation.WrongPassword":"Lozinka mora imati barem 6 znakova, od čega jedno malo, jedno veliko slovo i jedan broj.",
	"Settings.Table.TaxCurrency.companyInVATInfo":"Ako odaberete da je izdavatelj računa (vi ili vaša tvrtka) u sustavu PDV-a (poreza na dodanu vrijednost), tada će vam svaka stavka ponude/računa imati u sebi PDV kojeg odaberete niže. U suprotnom, nećete imati mogućnost stavljanja PDV-a u svoje ponude/račune.",
	"Allaround.Message.packageLimitReached":"Dosegli ste dozvoljeni broj {{offersOrInvoicesGenitiv}} u ovom mjesecu. Uvjete prelaska na veći paket možete pogledati <a href=\\\"http://www.izracunko.com/cijene#nadoplata\\\" target=\\\"_blank\\\">ovdje (kliknite)</a>.",
	"Settings.PrintSettings.Table.Columns.itemPosition":"Naziv stupca rednog broja stavke",
	"Invoice.SendEInvoice.downloadAfterSendYesNo":"Preuzmi eRačun u XML obliku nakon slanja",
	"Settings.Users.Edit.password":"Lozinka",
	"Allaround.Currency.InvoiceOrTenderLanguage.sr-Latn":"Srpski",
	"Invoice.Send.messageFail":"Mail nije uspješno poslan",
	"Invoice.Header.Menu.Message.warningNoItems":"Prethodno trebate dodati barem jednu stavku.",
	"Settings.Table.Languages.interfaceLanguage":"Jezik sučelja",
	"Settings.PrintSettings.Table.Pictures.NewHeader.infoNote":"Odaberite sliku za slanje sa svog računala (u obzir dolaze sljedeće vrste slika: .jpg, .png, .bmp, .gif). Maksimalna veličina datoteke je 200 KB. Slika će biti smanjena na 2.5 cm po visini i proporcionalno po širini.\r\nNapomena: za optimalan ispis Vaših ponuda i računa preporučujemo sliku maksimalne širine 2008 px i maksimalne visine 295 px uz rezoluciju od barem 300 dpi).",
	"Invoice.Table.Footer.personResponsibleTitle":"Odgovorna osoba",
	"Invoice.Table.Header.amount":"Količina",
	"Settings.SalesPlaces.Edit.Table.description":"Opis",
	"Invoice.Table.Client.title":"Klijent",
	"Templates.Side.searchPlaceholder":"Predložak (dio naziva)",
	"Settings.Users.Edit.Message.newUser":"Ukoliko želite da korisnik može sve raditi što i Vi, odaberite opciju \"(Da) Korisnik je administrator\". Ukoliko smatrate kako je bolje da korisnik ne može mijenjati cjenik, postavke, raditi račune te da nema uvid u nabavne cijene i marže, nemojte je odabrati (Ne).",
	"Dashboard.searchButton":"Traži",
	"Clients.AgreedPrices.Table.ItemMenu.edit":"Uredi",
	"Settings.MyPictures.Table.UploadPicture.selectPictureButton":"Dodaj",
	"Settings.SalesPlaces.Side.searchTitle":"Pretraživanje",
	"Invoice.Table.Totals.vat":"PDV {{vatPercentage}}",
	"Invoice.Send.message":"Tekst e-maila",
	"Settings.Users.Side.searchPlaceholder":"Dio imena korisnika",
	"PriceList.DeleteGroup.questionYesNo":"Da li zaista želite obrisati grupu {{groupName}} i sve njene podgrupe i proizvode?",
	"Popup.yes":"Da",
	"Settings.Users.Edit.Message.SuccessCreate":"Korisnik je uspješno dodan.",
	"Settings.PrintSettings.Table.Invoice.textWithOfferNumber":"Tekst uz broj ponude",
	"Settings.Users.Edit.userIsAdmin":"Korisnik je administrator",
	"Settings.PrintSettings.Table.Pictures.NewSignature.signatureInfo":"Odaberite sliku za slanje sa svog računala (u obzir dolaze sljedeće vrste slika: .jpg, .png, .bmp, .gif). Maksimalna veličina datoteke je 200 KB. Slika će biti smanjena na 7 cm širine ili 4 cm visine ako je veća od toga (proporcionalno prema originalu).",
	"Header.signOut":"Odjavi se",
	"Invoice.SaveTemplate.Message.errorCreate":"Greška prilikom spremanja predloška.",
	"Invoice.Edit.salesPrice":"Prodajna cijena",
	"Invoices.Table.Mobile.client":"Klijent",
	"Settings.MyPictures.Messages.successUpload":"Slika uspješno dodana.",
	"Offers.Side.offerNumberPlaceholder":"Broj ponude (dio broja)",
	"Invoice.SaveTemplate.Message.successUpdate":"Predložak uspješno spremljen.",
	"Invoice.Header.pageTitleNewOffer":"Nova ponuda",
	"Settings.PrintSettings.Table.Pictures.NewHeader.pageTitleNew":"Dodaj zaglavlje",
	"Invoice.Side.cancelInvoice":"Storniraj račun",
	"Invoice.CancelInvoice.pageTitle":"Storniraj račun",
	"Dashboard.Chart.Income":"Prihod",
	"Settings.Table.Pricelist.showPurchasePrice":"U svom cjeniku želim imati nabavnu cijenu i maržu (uz prodajnu cijenu)",
	"Settings.PrintSettings.TextContact.telFaxInfo":"Ovaj tekst će se pojaviti ako unesete isti broj telefona i faxa.",
	"PriceList.DeleteGroup.successMessage":"Grupa {{groupName}} uspješno obrisana.",
	"Invoice.SendEInvoice.pageTitleDownload":"Preuzimanje eRačuna",
	"PriceList.EditGroup.pictureNew":"Dodaj sliku",
	"Settings.Table.TaxCurrency.useTotalPriceInfoTitle":"Sveukupna cijena",
	"Allaround.Currency.EUR.shortcode":"€",
	"PriceList.Import.Message.warningText":"If you already have same products in the price list, their prices will be overwritten with prices from the XML file.",
	"Login.loginButton":"Prijavi se",
	"Allaround.Validation.incorrectFileType":"Nedozvoljeni tip dokumenta. Dozvoljeni tipovi su: {{allowedFileTypes}}.",
	"Invoices.Table.Header.invoiceNumber":"Račun broj",
	"Settings.PrintSettings.Table.Pictures.NewHeader.pageTitleEdit":"Zamijeni zaglavlje",
	"Settings.PrintSettings.Table.Invoice.textWithPersonInCharge":"Tekst uz ime odgovorne osobe",
	"Allaround.Message.subscriptionExpired":"Vaš paket istekao je <b>{{0:dd.MM.yyyy}}</b>. Automatski ste prešli u paket besplatno, u kojem možete napraviti znatno manji broj dokumenata i nemate mogućnost fiskalizacije računa. Za prelazak na veći paket <a href=\\\"http://www.izracunko.com/cijene\\\" target=\\\"_blank\\\">kliknite ovdje</a>.",
	"Settings.Users.Side.searchButton":"Traži",
	"Settings.Users.Delete.ErrorDelete":"Korisnik {{userName}} nije izbrisan.",
	"Settings.Users.Edit.personTitlePlaceholder":"direktor",
	"Settings.Fiscalisation.Table.CurrentSettings.certificateValidUntil":"Vrijedi do",
	"Settings.PrintSettings.TextContact.IBANDefaultText":"IBAN:",
	"Reports.Type.revenuePerDay":"Promet po danima",
	"Validation.number":"Number",
	"Invoice.Print.invoice":"Račun",
	"Invoice.DeleteInvoice.Message.ErrorDelete":"Račun nije uspješno obrisan.",
	"Settings.Fiscalisation.Messages.successDeactivation":"Fiskalizacija deaktivirana.",
	"Settings.PrintSettings.Table.Pictures.NewHeader.warningChangeNote":"Slanje novog zaglavlja prebrisat će postojeće!\r\nZbog kvalitete ispisa vaših dokumenata, trebate imate sliku kvalitete barem 300 dpi (točaka po inču).",
	"Registration.registerButton":"Registriraj se",
	"PriceList.EditProduct.salesPrice":"Prodajna cijena",
	"Invoice.Edit.remarkPlaceholder":"Unesite napomenu",
	"Clients.AgreedPrices.Edit.Group.Message.errorSave":"Uvjeti za grupu {{groupName}} nisu uspješno spremljeni.",
	"Settings.PrintSettings.Table.Invoice.PaymentTypes.textWithWayOfPaymentDefaultText":"Način plaćanja",
	"PriceList.Import.pageTitle":"Uvezi cjenik",
	"Invoice.Print.offer":"Ponuda",
	"Settings.Table.TaxCurrency.title":"Porez i valuta",
	"Invoice.CancelInvoice.Message.CancelDelete":"Odustali ste od storniranja računa.",
	"Footer.about":"O programu",
	"Settings.Users.Edit.userLabelPlaceholder":"npr. 01",
	"Clients.AgreedPrices.Table.Header.name":"Naziv proizvoda/grupe",
	"Settings.Fiscalisation.Table.UploadCertificate.selectCertificateDefaultText":"Certifikat nije odabran",
	"Clients.AgreedPrices.Side.conditionsTitle":"Opći uvjeti",
	"Invoices.Table.Mobile.amount":"Iznos",
	"Settings.Users.Edit.userIsAdminInfo":"Ukoliko želite da korisnik može sve raditi što i Vi, odaberite opciju '(Da) Korisnik je administrator'. Ukoliko smatrate kako je bolje da korisnik ne može mijenjati cjenik, postavke, raditi račune te da nema uvid u nabavne cijene i marže, nemojte je odabrati (Ne). I ako odaberete 'Ne', moći ćete omogućiti ovom korisniku da radi račune ako to želite.",
	"Settings.Table.Languages.offerLanguage":"Jezik ponude",
	"Registration.testimonial1Text":"Prvi program u životu u kojem ništa ne bi promijenio.",
	"Registration.testimonial1Name":"Mirko Budić, odvjetnik",
	"Clients.Edit.addressPlaceholder":"Unesi adresu",
	"Registration.testimonial4Text":"Ovaj vaš softver je fenomenalno nešto i to mi je toliko olakšalo rad da ja to ne mogu opisati.<br/>Svaka čast vašem timu, prezadovoljan sam.",
	"Registration.testimonial4Name":"Robert Koščak, Svijet komunikacija",
	"Offers.Side.searchTitle":"Pretraživanje",
	"Invoice.Header.pageTitleEditTemplate":"Uredi predložak",
	"Registration.testimonial2Text":"Ja sam 'tava' za ove stvari, ali meni je ovo savršeno.",
	"Registration.testimonial2Name":"Ankica Šolić, odvjetnica",
	"Clients.Edit.phonePlaceholder":"Unesi broj telefona",
	"Registration.testimonial3Text":"Koristimo program Izračunko za izradu ponuda i računa od rujna 2013 godine.<br/>Program nam je ubrzao proces izrade ponuda kako u desktop tako i u mobilnoj verziji. Prosječno trajanje izrade PDF-ponuda iznosi samo 3 MINUTE OD PRIMLJENOG UPITA.\r\nTakođer želim napomenuti da na sva pitanja pružaju kvalitetnu korisničku podršku.",
	"Registration.testimonial3Name":"Igor Čukac, Komet prijevoz",
	"Invoice.Side.delete":"Obriši",
	"Settings.PrintSettings.Table.Pictures.NewSignature.signatureRemoveQuestion":"Da li doista želite izbrisati trenutni potpis?",
	"Settings.Table.TaxCurrency.predefinedCurrencyInfo":"Valuta u kojoj unosimo vrijednosti u cjenik te iz cjenika prebacujemo u ponudu ili račun.",
	"Allaround.Button.save":"Spremi",
	"Allaround.Button.send":"Pošalji",
	"Settings.PrintSettings.Table.Pictures.NewFooter.warningChangeNote":"Slanje novog podnožja prebrisat će postojeće!\r\nZbog kvalitete ispisa vaših dokumenata, trebate imati sliku kvalitete barem 300 dpi (točaka po inču).",
	"Settings.Table.BasicInformation.namePlaceholder":"Unesi naziv onoga tko izdaje račune (tvrtke ili obrta ili vaše ime i prezime)",
	"PriceList.EditProduct.additionalInformation":"Dodatne informacije",
	"Settings.Users.NewUser.loginMail":"Login E-Mail",
	"Settings.Fiscalisation.Messages.errorActivationNoCertificate":"Fiskalizaciju nije moguće aktivirati bez certifikata.",
	"Settings.PrintSettings.Table.Invoice.textWithInvoiceDateDefaultText":"Datum i vrijeme",
	"Settings.EInvoice.Table.MyEInvoice.Title.infoTitle":"Moj eRačun servis",
	"Allaround.Button.yes":"Da"
 }