import React, { ReactNode, useEffect } from "react";
import { createPortal } from "react-dom";

const modalRoot = document.getElementById("modal-root");

export const Modal = ({ isOpen, children }: { isOpen: boolean, children: ReactNode }) => {

    useEffect(() => {
        if (isOpen)
            document.body.classList.add("modal-open");
        else
            document.body.classList.remove("modal-open");
    }, [isOpen]);

    if (!isOpen)
        return null;

    if (!modalRoot)
        return null;

    return createPortal(
        <div style={{ zIndex: 1200, display: "block" }}>
            {children}
        </div>,
        modalRoot
    )
}

export default Modal;