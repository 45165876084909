import dayjs from 'dayjs';
import c from 'currency.js';
import round from './round';

export const date = (date: Date | null | string) => {
    return date ? dayjs(date).format("DD.MM.YYYY") : "";
}

export const dateWithTime = (date: Date | null | string) => {
    return date ? dayjs(date).format("DD.MM.YYYY HH:mm") : "";
}

export const numberAsText = (amount: number | null) => {
    if (amount == null)
        return '';

    const hasDecimalPlaces = amount % 1 != 0

    if (hasDecimalPlaces)
    {
        const doesEndWithZero = (amount * 100) % 10 == 0;

        return doesEndWithZero 
        ? c(amount, { separator: '', decimal: ",", precision: 1, symbol: '' }).format()
        : c(amount, { separator: '', decimal: ",", precision: 2, symbol: '' }).format();

    }

    return round(amount).toString();
}

export const currencyAsText = (amount: number | null) => {
    return c(amount || "", { separator: '', decimal: ",", precision: 2, symbol: '' }).format()
}