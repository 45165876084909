
import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import { RootReducer } from './reducers/RootReducer';
import storage from 'redux-persist/lib/storage'

const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, RootReducer)

let store = createStore(persistedReducer)
let persistor = persistStore(store)

export default { store, persistor }