import React, { useMemo, useState, } from 'react'

import { useTranslation } from 'react-i18next'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { GridMenuActionLink, GridMenuCell } from '../../shared/grid/GridMenu';
import { useGridActions } from '../../shared/grid/GridContextProvider';
import { GridFilterForm, GridFilterInput } from '../../shared/grid/GridFilter';
import { Grid } from '../../shared/grid/Grid';
import { GridActions, GridData, TableParams } from '../../shared/grid/Types';
import { ApiClient } from '../../shared/api/api-client';
import { dateWithTime } from '../../shared/utils/formatters';
import { toastSuccess } from '../../shared/toastr';
import { TasksForm } from './TasksForm'
import { useOfferionModal } from '../../shared/modal/ConfirmationModal';
import './Tasks.css'

interface TaskRow {
    id: number;
    lastRunTime: string;
    name: string;
}

const AddNewTaskButton = ({ onSubmit }: { onSubmit: (task: {}) => Promise<void> }) => {
    const [hideOverlay, setHideOverlay] = useState(true)

    return (
        <>
            {hideOverlay ? (
                <div className="table__button-container">
                    <button className="button button--white table__button" onClick={() => setHideOverlay(false)}>
                        <span className="icon icon__plus"></span>
                    </button>
                    <span className="button-text table__button-text mod-margin-left-4">New Task</span>
                </div>
            ) : (
                <div className="button-container small-hide overlay__item overlay__item--bottom-left overlay__item--active">
                    <button className="button button--gray button--plus overlay__button overlay__button--active">
                        <span className="icon icon__plus"></span>
                    </button>

                    <TasksForm task={null}
                        closeOverlay={() => setHideOverlay(true)}
                        onSubmit={(t) => onSubmit(t).then(() => setHideOverlay(true))}
                    ></TasksForm>

                    <span className="button-text table__button-text mod-margin-left-4">New Task</span>
                </div>
            )}
        </>
    )
}


export const NewTaskButton = () => {

}

const Filter = ({ updateFilter }: { updateFilter: (filter: any[]) => void }) => {
    return (
        <GridFilterForm
            initialValues={{ name: '' }}
            onSubmit={v => {

                const filter: { property: string, operator: string, value: string }[] = [];

                if (v.name) {
                    filter.push({
                        property: 'Name',
                        operator: 'Contains',
                        value: v.name,
                    })
                }

                updateFilter(filter);
            }}
            titleKey="Search"
            searchButtonLabelKey="Clients.Side.searchButton" >
            <GridFilterInput name="name" placeholderKey="Name"></GridFilterInput>
        </GridFilterForm >
    )
}

export const TasksListContainer = () => {

    const gridActions = useGridActions();
    const { t } = useTranslation();
    const offerionModal = useOfferionModal();

    const deleteTask = (taskId: number, name: string) => {

        offerionModal.open('Delete task', `Are you sure that you want to delete ${name}?`, async () => {

            await ApiClient.delete(`/api/jobscheduler/DeleteScheduledJob/${taskId}`);

            gridActions.refresh();

            toastSuccess('Task was succesfully deleted')
        })

    }

    async function upsertTask(task: any) {

        await ApiClient.post(`/api/jobscheduler/UpsertScheduledJob`, task);

        gridActions.refresh();

        toastSuccess(`Task was succesfully ${task.id ? "updated" : "created"}`)
    }

    const RowComponent = ({ row, gridActions }: { row: TaskRow, gridActions: GridActions }) => {

        const [selectedTask, setSelectedTask] = useState<any | null>(null)

        const editTask = async (taskId: number) => {

            ApiClient.get<{}>(`/api/jobscheduler/GetScheduledJob/${taskId}`).then(setSelectedTask);
        }

        async function upsertTaskInternal(task: any) {

            await upsertTask(task);

            setSelectedTask(null);
        }

        return <>

            <span
                style={{ width: '300px' }}
                className="table__cell table__cell__title overlay__item overlay__item--right-middle">
                {row.name}
                {selectedTask && <TasksForm task={selectedTask}
                    closeOverlay={() => setSelectedTask(null)}
                    onSubmit={(t) => upsertTaskInternal(t)}
                ></TasksForm>}
            </span>
            <span style={{ width: '250px' }} className="table__cell table__cell__email table__cell--prefixed">
                {dateWithTime(row.lastRunTime)}
            </span>

            <GridMenuCell>
                <GridMenuActionLink onClick={() => editTask(row.id)} translationKey={`Edit`}></GridMenuActionLink>

                <GridMenuActionLink onClick={() => deleteTask(row.id, row.name)} translationKey="Delete"></GridMenuActionLink>
            </GridMenuCell>
        </>
    }

    const columns = [
        {
            key: 'Name',
            labelKey: 'Name',
            cssClass: 'table__header__item__name'
        },
        {
            key: 'LastRunTime',
            labelKey: 'Last Run Time',
            cssClass: 'table__header__item__name'
        },
        {
            cssClass: 'table__header__item__menu'
        },
    ]

    const rowsPromiseFactory = useMemo(() => {
        return (config: TableParams) => {
            const filter = (config.filter || []);

            return ApiClient.post<GridData<TaskRow>>('api/jobscheduler/GetAllScheduledJobs', {
                projectionName: "ScheduledJobsGridModel",
                page: config.pageNumber,
                count: config.pageSize,
                filters: filter
            })
        }
    }, [])

    return <main className="main">
        <BreadcrumbsItem to="/tasks">Tasks</BreadcrumbsItem>
        <div className="main__header">
            <div className="main-title">{t("Tasks")}</div>

            <div className="main__header-buttons">
                <AddNewTaskButton onSubmit={upsertTask} />
            </div>
        </div>

        <Grid
            emptyMessageKey={"No tasks found"}
            emptySearchMessageKey={"No tasks found"}
            translationKey={"Tasks List"}
            sidebarComponent={Filter}
            defaultPageSize={10}
            rowsPromiseFactory={rowsPromiseFactory}
            rowComponent={RowComponent}
            tableSchema={{ columns }}></Grid>
    </main >
}