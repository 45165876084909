
import React, { useState } from 'react';
import { usePopper } from 'react-popper';
import { Placement } from '@popperjs/core'
import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';

export const OfferionTooltip = ({ titleKey, bodyKey, placement, iconStyle }: { titleKey: string, bodyKey: string, placement?: Placement, iconStyle?: React.CSSProperties }) => {

    const [referenceElement, setReferenceElement] = useState<HTMLSpanElement | null>(null);
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
    const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
    const [tooltipVisible, setTooltipVisible] = useState(false);

    const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
        placement: placement,
        modifiers: [{ name: 'arrow', options: { element: arrowElement } }],
    });

    const { t } = useTranslation();

    const changeTooltipVisibility = () => {
        setTooltipVisible(!tooltipVisible)

        update && update();
    }

    return <>
        <span style={iconStyle} ref={(e) => setReferenceElement(e)} className="icon icon__info icon--clickable" onClick={() => changeTooltipVisibility()}></span>

        {createPortal(<div className="tooltip" ref={e => setPopperElement(e)} style={{ ...styles.popper, visibility: tooltipVisible ? "visible" : "hidden", fontWeight: "normal", zIndex: 9999999 }} {...attributes.popper} x-placement={attributes["popper"] ? attributes["popper"]["data-popper-placement"] : ""}>
            <div className="tooltip-arrow" x-arrow="" ref={e => setArrowElement(e)} style={styles.arrow} />
            <div className="tooltip-inner">
                <div className="tooltip__title">{t(titleKey)}</div>
                <div className="tooltip__body" dangerouslySetInnerHTML={{ __html: t(bodyKey) }}></div>
            </div>
        </div>, document.body)}
    </>
}