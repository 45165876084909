import React from 'react'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { ApiClient } from '../../shared/api/api-client'

export const ReportsContainer = () => {

    function exportDocument(endpointSufix: string, title: string) {
        return ApiClient.get(`api/reports/${endpointSufix}`, { responseType: 'arraybuffer' }).then((data: any) => {

            const blob = new Blob([data], { type: 'application/pdf' })
            const url = window.URL.createObjectURL(blob);
            const anchor = window.document.createElement("a");

            anchor.download = `${title}.csv`;
            anchor.href = url;
            anchor.target="_blank";
            anchor.click();
        });
    }

    return <>
        <BreadcrumbsItem to="/reports">Reports</BreadcrumbsItem>
        <main className="main">
            <form name="settingsForm">

                <div className="main__header">
                    <div className="main-title">Reports</div>

                </div>
                <div className="invoices__container curled-paper settings">

                    <h1 className="table__title table__title--short table__title--with-button">
                        Available Reports
                </h1>

                    <div className="info__container">

                        <div className="info__row print-active">
                            <label className="info__row-name" htmlFor="exportToCsv">Subscription information</label>
                            <div className="info__row-content info__row-content--with-button">
                                <span className="input__info-icon-holder input__wrapper--icon-inside info__input--with-button">
                                    <button name="exportToCsv" id="exportToCsv" onClick={() => exportDocument('ExportToCsv', 'Company Subscription Information')} className="search-form__button search-form__button--full-width button button--gray">
                                        <span className="button-text" style={{ float: "left" }}>Export to CSV</span>
                                        <span className="icon icon__document-export mod-margin-left-8"></span>
                                    </button>
                                </span>
                            </div>
                        </div>

                        <div className="info__row print-active">
                            <label className="info__row-name" htmlFor="exportToCsv">Invoices information</label>
                            <div className="info__row-content info__row-content--with-button">
                                <span className="input__info-icon-holder input__wrapper--icon-inside info__input--with-button">
                                    <button name="exportToCsv" id="exportToCsv" onClick={() => exportDocument('ExportInvoicesToCsv', 'Invoices Information')} className="search-form__button search-form__button--full-width button button--gray">
                                        <span className="button-text" style={{ float: "left" }}>Export to CSV</span>
                                        <span className="icon icon__document-export mod-margin-left-8"></span>
                                    </button>
                                </span>
                            </div>
                        </div>

                        <div className="info__row print-active">
                            <label className="info__row-name" htmlFor="exportToCsv">Offers information</label>
                            <div className="info__row-content info__row-content--with-button">
                                <span className="input__info-icon-holder input__wrapper--icon-inside info__input--with-button">
                                    <button name="exportToCsv" id="exportToCsv" onClick={() => exportDocument('ExportTendersToCsv', 'Offers Information')} className="search-form__button search-form__button--full-width button button--gray">
                                        <span className="button-text" style={{ float: "left" }}>Export to CSV</span>
                                        <span className="icon icon__document-export mod-margin-left-8"></span>
                                    </button>
                                </span>
                            </div>
                        </div>

                    </div>

                </div>

            </form>
        </main>
    </>
}