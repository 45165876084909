import React, { useEffect, useRef } from 'react'

import { useTranslation } from 'react-i18next'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { GridMenuActionLink, GridMenuCell } from '../../shared/grid/GridMenu';
import { toastSuccess } from '../../shared/toastr';
import { Grid } from '../../shared/grid/Grid';
import { GridActions, GridData, TableParams } from '../../shared/grid/Types';
import { ApiClient } from '../../shared/api/api-client';

interface CompanyRow {
    id: number;
    name: string;
    email: string;
    address: string;
    type: number;
}

const companyTypeToString = (companyType: number) => {
    switch (companyType) {
        case 0:
            return "Basic";

        case 1:
            return "Lawyer";

        case 2:
            return "Window Maker";

        case 3:
            return "Renter";

        case 4:
            return "Other"
    }
}

export const TemplateCompaniesListContainer = () => {

    const { t } = useTranslation();
    const iframeElRef = useRef<HTMLIFrameElement | null>(null);
    const accessTokenRef = useRef<string | null>(null);

    useEffect(() => {

        window.addEventListener("message", onMessage);

        function onMessage(e: MessageEvent<any>) {
            if (e.origin.endsWith("offerion.net") || e.origin.endsWith("izracunko.com")) {
                iframeElRef.current?.contentWindow?.postMessage(accessTokenRef.current, e.origin)

                accessTokenRef.current = null;
            }
        }

        return () => {
            window.removeEventListener("message", onMessage);
        }
    }, [])

    const impersonate = (companyId: number) => {
        ApiClient.post<{ accessToken: string, companyName: string, fullName: string }>("/api/impersonation/GenerateAccessToken",
            {
                companyId: companyId
            }).then(result => {

                const iframeEl = document.createElement("iframe");

                iframeEl.src = `${process.env.REACT_APP_SERVER_LOCATION}/impersonation`;
                iframeEl.hidden = true;
                iframeElRef.current = iframeEl;
                accessTokenRef.current = result.accessToken;

                //CHECK SECURITY

                toastSuccess(`Impersonation of ${result.companyName.toUpperCase()} as ${result.fullName.toUpperCase()} is about to start...`)

                setTimeout(() => {
                    document.body.appendChild(iframeEl);
                }, 4000)
            });
    }


    const RowComponent = ({ row, gridActions }: { row: CompanyRow, gridActions: GridActions }) => {

        return <>
            <span
                style={{ width: '300px' }}
                className="table__cell table__cell__title overlay__item overlay__item--right-middle">
                {row.name}
            </span>
            <span style={{ width: '250px' }} className="table__cell table__cell__email table__cell--prefixed">
                {companyTypeToString(row.type)}
            </span>

            <GridMenuCell>
                <GridMenuActionLink onClick={() => impersonate(row.id)} translationKey="Impersonate"></GridMenuActionLink>
            </GridMenuCell>
        </>
    }

    const columns = [
        {
            key: 'Name',
            labelKey: 'Company Name',
            cssClass: "table__header__item__name"
        },
        {
            key: 'CompanyType',
            labelKey: 'Type',
        },
        {
            cssClass: 'table__header__item__menu'
        },
    ]

    return <main className="main">
        <BreadcrumbsItem to="/companies">Template Companies</BreadcrumbsItem>
        <div className="main__header">
            <div className="main-title">{t("Template Companies")}</div>

            <div className="main__header-buttons">
            </div>
        </div>

        <Grid
            emptyMessageKey={"No tempalte companies found"}
            emptySearchMessageKey={"No tempalte companies found"}
            translationKey={"Template Companies List"}
            sidebarComponent={null}
            defaultPageSize={5}
            rowsPromiseFactory={(config: TableParams) => {
                const filter = (config.filter || []);

                filter.push({
                    property: "CompanyType",
                    value: "1",
                    operator: "Equal"
                });

                return ApiClient.post<GridData<CompanyRow>>('api/admin/getCompaniesList', {
                    projectionName: "AdminCompaniesGridModel",
                    page: config.pageNumber,
                    count: config.pageSize,
                    filters: filter
                });
            }}
            rowComponent={RowComponent}
            tableSchema={{ columns }}></Grid>
    </main>
}