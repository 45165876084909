
import React, { useState, ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Formik, Field, Form, FieldArray } from 'formik';
import { useHistory, Link, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { OfferionErrorMessage } from '../../shared/forms/validation/OfferionErrorMessage';
import { InlineDateEdit } from '../../shared/forms/InlineDateEdit';
import { ApiClient } from '../../shared/api/api-client';
import { CurrencyField } from '../../shared/forms/CurrencyField';
import { toastSuccess } from '../../shared/toastr';

const Section = ({ sectionTitleKey, children, expanded }: { sectionTitleKey: string, children: ReactNode, additionalButtonsComponent?: React.ComponentType, expanded?: boolean }) => {
    const { t } = useTranslation();

    const [isCollapsed, setIsCollapsed] = useState(!expanded);

    return <>
        <div className="invoices__title invoices__title--short  invoices__title--with-button">
            <h1 style={{ display: "inline", cursor: "pointer" }} onClick={() => setIsCollapsed(!isCollapsed)}>{t(sectionTitleKey)}</h1>
            <button type="button" className="button button--gray right" onClick={() => setIsCollapsed(!isCollapsed)}>
                <span className={`${isCollapsed ? 'icon icon__down-blue' : 'icon icon__up'}`}></span>
            </button>
        </div>

        <div className="table__outer info__additional info__additional--hide" style={{ height: isCollapsed ? "0px" : "auto" }}>
            <div className="info__container">
                {children}
            </div>
        </div>
    </>
}

export const AppSettingsContainer = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [settings, setSettings] = useState<{} | null>(null)

    useEffect(() => {
        ApiClient.get<{}>(`/api/AdminCompanySettings/GetDefaultSettings`).then(setSettings)
    }, [])

    async function onSubmit(settings: {}) {
        await ApiClient.post('api/AdminCompanySettings/UpdateDefaultSettings', settings);

        toastSuccess('Global application settings updated');
    }

    if (!settings)
        return null;

    return <>
        <BreadcrumbsItem to="/app-settings">Settings</BreadcrumbsItem>
        <main className="main">
            <Formik initialValues={settings} onSubmit={onSubmit} >
                {
                    formikProps => {

                        return <Form noValidate={true}>
                            <div className="main__header">
                                <div className="main-title">Settings</div>

                                <div className="main__header-buttons">
                                    <div className="button-outer">
                                        <button type="submit" className="button button--white button--padded">
                                            <span className="icon icon__save"></span>
                                            <span className="button-text">{t('Allaround.Button.save')}</span>
                                        </button>
                                    </div>
                                    <div className="button-outer">
                                        <button className="button button--white button--padded" type="button" onClick={history.goBack}>
                                            <span className="icon icon__delete"></span>
                                            <span className="button-text">{t('Allaround.Button.cancel')}</span>
                                        </button>
                                    </div>

                                </div>

                            </div>



                            <div className="invoices__container curled-paper settings">

                                <Section sectionTitleKey='Payment Settings' expanded={true}>

                                    <div className="info__row">
                                        <label className="info__row-name" htmlFor="">Number of invoices in trial period</label>
                                        <div className="info__row-content info__row-content--with-button">
                                            <div className="">
                                                <Field name="numberOfReceipts" type="number" />
                                            </div>
                                        </div>
                                        <OfferionErrorMessage name="numberOfReceipts" />
                                    </div>

                                    <div className="info__row">
                                        <label className="info__row-name" htmlFor="">Number of offers in trial period</label>
                                        <div className="info__row-content info__row-content--with-button">
                                            <div className="">
                                                <Field name="numberOfTenders" type="number" />
                                            </div>
                                        </div>
                                        <OfferionErrorMessage name="numberOfTenders" />
                                    </div>

                                    <div className="info__row">
                                        <label className="info__row-name" htmlFor="">Number of users in trial period</label>
                                        <div className="info__row-content info__row-content--with-button">
                                            <div className="">
                                                <Field name="numberOfUsers" type="number" />
                                            </div>
                                        </div>
                                        <OfferionErrorMessage name="numberOfUsers" />
                                    </div>

                                    <div className="info__row">
                                        <label className="info__row-name" htmlFor="">Number of sale places in trial period</label>
                                        <div className="info__row-content info__row-content--with-button">
                                            <div className="">
                                                <Field name="numberOfSalePlaces" type="number" />
                                            </div>
                                        </div>
                                        <OfferionErrorMessage name="numberOfSalePlaces" />
                                    </div>

                                    <div className="info__row">
                                        <label className="info__row-name" htmlFor="">Number of invoices in package after trial period</label>
                                        <div className="info__row-content info__row-content--with-button">
                                            <div className="">
                                                <Field name="numberOfReceiptsAfterGracePeriod" type="number" />
                                            </div>
                                        </div>
                                        <OfferionErrorMessage name="numberOfReceiptsAfterGracePeriod" />
                                    </div>

                                    <div className="info__row">
                                        <label className="info__row-name" htmlFor="">Number of offers in package after trial period</label>
                                        <div className="info__row-content info__row-content--with-button">
                                            <div className="">
                                                <Field name="numberOfTendersAfterGracePeriod" type="number" />
                                            </div>
                                        </div>
                                        <OfferionErrorMessage name="numberOfTendersAfterGracePeriod" />
                                    </div>

                                    <div className="info__row">
                                        <label className="info__row-name" htmlFor="">Number of users in package after trial period</label>
                                        <div className="info__row-content info__row-content--with-button">
                                            <div className="">
                                                <Field name="numberOfUsersAfterGracePeriod" type="number" />
                                            </div>
                                        </div>
                                        <OfferionErrorMessage name="numberOfUsersAfterGracePeriod" />
                                    </div>

                                    <div className="info__row">
                                        <label className="info__row-name" htmlFor="">Number of sale places in package after trial period</label>
                                        <div className="info__row-content info__row-content--with-button">
                                            <div className="">
                                                <Field name="numberOfSalePlacesAfterGracePeriod" type="number" />
                                            </div>
                                        </div>
                                        <OfferionErrorMessage name="numberOfSalePlacesAfterGracePeriod" />
                                    </div>

                                    <div className="info__row">
                                        <label className="info__row-name" htmlFor="">Trial period length in days</label>
                                        <div className="info__row-content info__row-content--with-button">
                                            <div className="">
                                                <Field name="gracePeriodLengthInDays" type="number" />
                                            </div>
                                        </div>
                                        <OfferionErrorMessage name="gracePeriodLengthInDays" />
                                    </div>


                                    <div className="info__row">
                                        <label className="info__row-name" htmlFor="">Number of days before expiration to show package warning</label>
                                        <div className="info__row-content info__row-content--with-button">
                                            <div className="">
                                                <Field name="numberOfDaysBeforeExpirationToShowPackageWarning" type="number" />
                                            </div>
                                        </div>
                                        <OfferionErrorMessage name="numberOfDaysBeforeExpirationToShowPackageWarning" />
                                    </div>

                                    <div className="info__row">
                                        <label className="info__row-name" htmlFor="">Number of days after expiration to show package warning</label>
                                        <div className="info__row-content info__row-content--with-button">
                                            <div className="">
                                                <Field name="numberOfDaysAfterExpirationToShowPackageWarning" type="number" />
                                            </div>
                                        </div>
                                        <OfferionErrorMessage name="numberOfDaysAfterExpirationToShowPackageWarning" />
                                    </div>
                                </Section>

                            </div>

                            <div className="main__footer">
                                <div className="main__footer-buttons main__footer-buttons--mobile-only">
                                    <div className="button-outer">
                                        <button type="submit" className="button button--white button--padded">
                                            <span className="icon icon__save"></span>
                                            <span className="button-text">{t('Allaround.Button.save')}</span>
                                        </button>
                                    </div>
                                    <div className="button-outer">
                                        <button className="button button--white button--padded" type="button" onClick={history.goBack}>
                                            <span className="icon icon__delete"></span>
                                            <span className="button-text">{t('Allaround.Button.cancel')}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    }}
            </Formik>
        </main>
    </>
}