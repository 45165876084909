import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRootStoreSelector } from '../../shared/store/hooks/useRootStoreSelector'
import { Breadcrumbs } from './Breadcrumbs'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { Link, useLocation } from 'react-router-dom'

export const Header = () => {
    const [menuVisible, setMenuVisible] = useState(false)
    const { t } = useTranslation()
    const authenticated = useRootStoreSelector(state => state.user.authenticated)

    const path = useLocation();

    const links = [
        {
            icon: "icon__document-star-white",
            to: "/companies",
            translationKey: "Companies"
        },
        {
            icon: "icon__document-star-white",
            to: "/company-templates",
            translationKey: "Company Templates"
        },
        {
            icon: "icon__document-star-white",
            to: "/app-settings",
            translationKey: "Settings"
        },
        {
            icon: "icon__document-star-white",
            to: "/reports",
            translationKey: "Reports"
        },
        {
            icon: "icon__document-star-white",
            to: "/tasks",
            translationKey: "Tasks"
        },
        {
            icon: "icon__document-star-white",
            to: "/document-translations",
            translationKey: "Translations"
        },
    ].map(link => {

        return <li className={`nav__list-item ${path.pathname.startsWith(link.to) ? 'nav__list-item--active' : ''}`}>
            <Link to={link.to} onClick={() => setMenuVisible(!menuVisible)}>
                <span className={`icon ${link.icon}`}></span>
                <span className="mod-margin-left-4">{t(link.translationKey)}</span>
            </Link>
        </li>
    });

    return (
        <header className="header">
            <div className="header__navigation">
                <Link to="/">
                    <img className="header__logo" src="/assets/images/logo_2x.png" alt="Offerion logo" />
                </Link>
                {authenticated ? (
                    <>
                        <Link className="header__menu-icon js-menu icon-menu" to="#" onClick={() => setMenuVisible(!menuVisible)}></Link>

                        <nav className={`nav ${menuVisible ? 'nav--show' : ''}`}>
                            <ul className="nav__list">

                                {links}

                                <li className="nav__list-item nav__list-item--logout mobile-only">
                                    <Link to="/signout">
                                        <span className="icon icon__logout"></span>
                                        <span className="mod-margin-left-4">{t('Header.signOut')}</span>
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                        <Link to="/signout" className="header__sign-out icon-sign_out">
                            <span className="icon-sign-out"></span>
                            <span className="mod-margin-left-4">{t('Header.signOut')}</span>
                        </Link>
                    </>
                ) : null}
            </div>

            {authenticated && <Breadcrumbs />}
        </header>
    )
}