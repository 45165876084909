import React from "react";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field } from "formik";
import { Link, useHistory, Redirect } from "react-router-dom";
import { LoginApi } from "./LoginApi";
import * as Yup from "yup";
import { OfferionErrorMessage } from "../../shared/forms/validation/OfferionErrorMessage";
import { useDispatch } from "react-redux";
import { UpdateAuthenticatedUser } from "../../shared/store/actions/userActions";
import { useRootStoreSelector } from "../../shared/store/hooks/useRootStoreSelector";
import { toastError } from "../../shared/toastr";

export const LoginContainer = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useRootStoreSelector(state => state.user);

  if (user.authenticated) return <Redirect to="dashboard" />;

  const onSubmit = async (values: { email: string; password: string }) => {

    try {
      const { accessToken } = await LoginApi.login(values.email, values.password);

      dispatch(UpdateAuthenticatedUser({ accessToken, authenticated: true }));

      history.push("/dashboard");
    }
    catch {
      toastError("Login.popupErrorWrongUser");
    }
  };

  const schema = Yup.object().shape({
    email: Yup.string()
      .required(t("Allaround.Validation.required", { fieldName: t("Login.email") })),
    password: Yup.string()
      .required(t("Allaround.Validation.required", { fieldName: t("Login.password") }))
      .min(6, t("Allaround.Validation.minLength", { fieldName: t("Login.password"), minLength: 6 })),
  });

  return (
    <main className="login__container">
      <h1 className="login__title">{t("Login.pageTitle")}</h1>
      <Formik
        validationSchema={schema}
        initialValues={{ email: "", password: "" }}
        onSubmit={onSubmit}
      >
        {formikProps => {
          return (
            <Form noValidate={true} autoComplete="false">
              <div className="login__form">
                <div className="login__form-group">
                  <Field
                    // ng-className="(loginForm.userEmail.$invalid && loginForm.userEmail.$touched)? 'login__input error' : 'login__input'"

                    id="email"
                    type="email"
                    placeholder={t("Login.email")}
                    name="email"
                  // ng-className="{'error-message': loginForm.userEmail.$invalid && !loginForm.userEmail.$pristine}"
                  />
                  <OfferionErrorMessage name="email" />
                </div>

                <div className="login__form-group">
                  <Field
                    // ng-className="(loginForm.userPassword.$invalid && loginForm.userPassword.$touched)? 'login__input error' : 'login__input'"

                    id="password"
                    type="password"
                    placeholder={t("Login.password")}
                    name="password"
                  />
                  <OfferionErrorMessage name="password" />
                </div>

                <button type="submit" className="login__button button--blue button--padded">
                  {t("Login.loginButton")}
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </main>
  );
};
