import axios, { AxiosPromise, AxiosRequestConfig } from 'axios'
import storeConfig from '../store/Store'
import { Spinner } from '../../services/Spinner'
import { toastError } from '../toastr';

const UNAUTHORIZED_STATUS_CODE = 401

let activeRequestsCounter = 0

export interface IApiClient {
    get: <TReturn>(url: string, data?: {}, headers?: {}, config?: { ignoreLoader?: boolean }) => Promise<TReturn>
    post: <TReturn>(url: string, data?: {}, headers?: {}, config?: { ignoreLoader?: boolean } & AxiosRequestConfig) => Promise<TReturn>
    put: <TReturn>(url: string, data?: {}, headers?: {}, config?: { ignoreLoader?: boolean }) => Promise<TReturn>
    delete: <TReturn>(url: string, data?: {}, headers?: {}, config?: { ignoreLoader?: boolean }) => Promise<TReturn>
}

const instance = axios.create({
    baseURL: process.env.REACT_APP_SERVER_LOCATION
})

instance.interceptors.request.use(
    function (config) {

        if (!(config as any).ignoreLoader) {
            activeRequestsCounter++

            Spinner.show()
        }

        const accessToken = storeConfig.store.getState().user.accessToken

        if (accessToken) {
            config.headers['x-AdminAccessToken'] = `${accessToken}`
            config.headers['Accept-Language'] = 'hr-0'
        }

        return config
    },
    function (error) {
        activeRequestsCounter = Math.max(0, activeRequestsCounter - 1);

        if (activeRequestsCounter == 0)
            Spinner.hide()

        toastError('Allaround.Message.unknownError');

        return Promise.reject(error)
    }
)

instance.interceptors.response.use(
    function (response) {

        if ((response.config as any).ignoreLoader)
            return response;

        activeRequestsCounter = Math.max(0, activeRequestsCounter - 1);

        if (activeRequestsCounter == 0) Spinner.hide()

        return response
    },
    function (error) {
        activeRequestsCounter = Math.max(0, activeRequestsCounter - 1);

        if (activeRequestsCounter == 0) Spinner.hide()

        toastError('Allaround.Message.unknownError');

        return Promise.reject(error)
    }
)

function callApi<T>(method: any, url: string, data: any, headers = {}, customData = {}): AxiosPromise<T> {
    const config = { ...customData, headers };

    return (instance as any)[method](url, data, config).then((r: any) => r.data)
}

const get = callApi.bind(null, 'get')
const post = callApi.bind(null, 'post')
const put = callApi.bind(null, 'put')
const del = callApi.bind(null, 'delete')

export const ApiClient = { get, post, put, delete: del } as IApiClient
