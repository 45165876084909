import { FieldProps } from 'formik';
import React, { useEffect, useState } from 'react'
import { currencyAsText, numberAsText } from '../utils/formatters';

export const CurrencyField = (props: FieldProps<number | null> & { placeholder: string, onChange: (value: number | "") => void }) => {

    const [value, setValue] = useState("");

    useEffect(() => {

        setValue(currencyAsText(props.field.value))

    }, [props.field.value])

    return <input type="text"
        name={props.field.name}
        onKeyDown={e => {
            const key = e.key;

            const isValidKey = key == 'Backspace' || key == 'Delete' || key == ',' || key == '.' || /\d/.test(key);

            if (!isValidKey) {
                e.preventDefault();
            }
        }}
        onFocus={() => {
            setValue(props.field.value == 0 ? '' : numberAsText(props.field.value))
        }}
        onChange={(e) => setValue(e.target.value)}
        onBlur={e => {
            const value = e.target.value

            let finalValue: number | "";

            if (value) {
                const formattedNumber = value.replace(",", ".");

                finalValue = Number.parseFloat(formattedNumber)

                setValue(currencyAsText(finalValue));
            }
            else if (props.field.value == 0) {
                finalValue = 0;
                setValue(currencyAsText(0));
            }
            else {
                finalValue = "";
            }

            if (props.onChange)
                props.onChange(finalValue);
            else
                props.form.setFieldValue(props.field.name, finalValue);
        }}
        placeholder={props.placeholder}
        value={value} />
}