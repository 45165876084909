import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import './App.css'

import { AuthenticatedRoute } from './shared/routing/AuthenticatedRoute'
import { Header } from './components/ui/Header'

import { LoginContainer } from './components/login/LoginContainer'
import { useRootStoreSelector } from './shared/store/hooks/useRootStoreSelector'
import { SpinnerLoader } from './components/ui/SpinnerLoader'
import { CompaniesListContainer } from './components/companies/ConcreteCompaniesListContainer'
import { CompanySettingsContainer } from './components/companies/CompanySettingsContainer'
import { TemplateCompaniesListContainer } from './components/companies/TemplateCompaniesListContainer'
import { ReportsContainer } from './components/reports/ReportsContainer'
import { AppSettingsContainer } from './components/app/AppSettingsContainer'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { ToastContainer } from 'react-toastify'
import { DocumentTranslationsContainer } from './components/document-translations/DocumentTranslationsContainer'
import 'react-toastify/dist/ReactToastify.minimal.css';
import { SignoutContainer } from './components/signout/SignoutContainer'
import { TasksListContainer } from './components/tasks/TasksListContainer'

function App() {
    const isAuthenticated = useRootStoreSelector(state => state.user.authenticated)

    return (
        <>
            <ToastContainer />

            <Header />

            <div className="push-footer" id="maindiv">
                <div ui-view="content">


                    <BreadcrumbsItem to="/">
                        Izračunko
                            </BreadcrumbsItem>


                    <SpinnerLoader />

                    <Switch>
                        <Route path="/login" component={LoginContainer}></Route>
                        <AuthenticatedRoute path="/companies" component={CompaniesListContainer}></AuthenticatedRoute>
                        <AuthenticatedRoute path="/company/settings/:companyId" component={CompanySettingsContainer}></AuthenticatedRoute>
                        <AuthenticatedRoute path="/company-templates" component={TemplateCompaniesListContainer}></AuthenticatedRoute>
                        <AuthenticatedRoute path="/app-settings" component={AppSettingsContainer}></AuthenticatedRoute>
                        <AuthenticatedRoute path="/reports" component={ReportsContainer}></AuthenticatedRoute>
                        <AuthenticatedRoute path="/document-translations" component={DocumentTranslationsContainer}></AuthenticatedRoute>
                        <AuthenticatedRoute path="/tasks" component={TasksListContainer}></AuthenticatedRoute>
                        <AuthenticatedRoute exact path="/signout" component={SignoutContainer}></AuthenticatedRoute>

                        <Route
                            path="/"
                            render={() => {
                                return isAuthenticated ? <Redirect to="/companies" /> : <Redirect to="/login" />
                            }}
                        />

                    </Switch>

                </div>
            </div>
            <div ui-view="footer"></div>

        </>
    )
}

export default App
