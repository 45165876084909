import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import i18n from "./translations/i18n";
import { I18nextProvider } from "react-i18next";
import { HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import storeConfig from "./shared/store/Store";
import { BreadcrumbsProvider } from 'react-breadcrumbs-dynamic'
import { GridContextProvider } from "./shared/grid/GridContextProvider";
import { PersistGate } from 'redux-persist/integration/react'
import { ConfirmationModal } from './shared/modal/ConfirmationModal'

const { store, persistor } = storeConfig;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <I18nextProvider i18n={i18n}>
          <ConfirmationModal>

            <HashRouter>
              <BreadcrumbsProvider>
                <GridContextProvider>
                  <App />
                </GridContextProvider>
              </BreadcrumbsProvider>
            </HashRouter>
          </ConfirmationModal>
        </I18nextProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
